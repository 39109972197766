import navbarContent from '../content/navbarContent';
import aboutContent from '../content/aboutContent';
import eventsContent from '../content/eventsContent';
import blogContent from '../content/blogContent';
const initState = {
    navbar: navbarContent,
    about: aboutContent,
    events: eventsContent,
    blog: blogContent,
}

const rootReducer = (state = initState, action) => {
    return state;
}

export default rootReducer;