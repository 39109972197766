import React from 'react'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import '../../styles/Events.css';
import EventBadge from '../PageComponents/Events/EventBadge';
import EventButtons from '../PageComponents/Events/EventButtons';
// import PlannedEvents from './PlannedEvents';

function Events(props) {



    const eventDateAsDate = (event) => {
        const eventDateCorrectFormat = event.date
            .replace('st', '')
            .replace('nd', '')
            .replace('rd', '')
            .replace('th', '')

        return new Date(eventDateCorrectFormat + " 11:59 PM EST");
    }

    const showDate = (event) => {
        return (
            <span className="event-date">{event.date}</span>
        )
    }
    const showDateWithTime = (event) => {
        return (
            <span className="event-date">{event.date} ({event.time})</span>
        )
    }

    const getEventButtons = (event) => {
        var eButtonProps = {
            link: `/events/${event.url}`,
        }
        if (event.rsvp) {
            if (event.rsvp.forceShow !== false) {
                if (event.rsvp.forceShow === true) {
                    eButtonProps.rsvp = '/events/' + event.url + '/rsvp';
                } else {
                    if (event.date) {
                        if (new Date() <= eventDateAsDate(event)) {
                            eButtonProps.rsvp = '/events/' + event.url + '/rsvp';
                        }
                    }
                }
            }
        }
        return (
            <EventButtons {...eButtonProps} />
        )
    }

    return (
        <div className="events_page">
            <Helmet>
                <title>Events | FAU Google Developer Student Club</title>
                <meta name="description" value="Bridging the gap between theory and practice through interactive, hands-on events focused on developer tools." />
                <link rel="canonical" href="https://gdscfau.com/events" />
            </Helmet>
            <section className="event-main-section">
                <div className="call-to-action">
                    <h1 className="title">Events</h1>
                    We plan awesome events for everyone. And yes! We like to study and chill.
                    <br />
                    {/* <PlannedEvents /> */}
                </div>

            </section>


            <section className="event_description">

                {
                    props.events.map((event) => {
                        if (event.hide === true) {
                            return null;
                        }
                        if (event.hideFromList === true) {
                            return null;
                        }
                        if (event.sep) {
                            return (
                                <div key={event.id} className="event-year-sep" id={event.id}>
                                    {event.sep}
                                </div>
                            )
                        }

                        return (
                            <div id={event.url} key={event.url} className="event">
                                {/* {event.name} */}
                                <EventBadge events event={event} />
                                <h3>{event.name}</h3>
                                {event.type.map(eType => {
                                    return (<strong key={eType} className="event-type">{eType}</strong>)
                                })
                                }
                                {/* <span className="event-date">{ }</span> */}
                                {
                                    event.time ? ((new Date() <= eventDateAsDate(event) || event.forceShowTime === true) ? showDateWithTime(event) : showDate(event)) : showDate(event)
                                }
                                {event.summary}
                                {
                                    getEventButtons(event)
                                }

                            </div>
                        )
                    })
                }


            </section>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        events: state.events
    }
}
export default connect(mapStateToProps, () => { return {} })(Events)