import React from 'react'
import { Link } from 'react-router-dom';
import EventSection from './EventSection';

function NeedHelpPredefined() {
    return (
        <EventSection>
            <span className="section_title">Need help?</span>
            <p>Our tech leads are here to help! They can help guide you through labs, answer any questions about the
                content, and provide a more comprehensive overview of the content. Reach out to them in the <Link
                    to="/go/discord">Discord
                    server</Link>.</p>
            <p>Take the opportunity to discuss these topics with other members in the club. Get to know them, ask
            questions, and provide help. This is a great way to find potential team members for the Solution
                Challenge. Make sure to guide members to the correct solution rather than giving them the answer.</p>
        </EventSection>
    )
}

export default NeedHelpPredefined
