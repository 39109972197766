import React from 'react'

function PageContent(props) {
    return (
        <section className="home-main-section">
            <div className="call-to-action">
                {props.children}
            </div>
        </section>
    )
}

export default PageContent
