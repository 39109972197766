import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Events from './components/Pages/Events';
import Event from './components/Pages/Event';
import Navbar from './components/Nav/Navbar';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Footer from './components/Footer/Footer';
import PageCircles from './components/Footer/PageCircles';
import NotFound from './components/Pages/NotFound';
import Redirects from './_redirects';
import FlutterInstall from './components/Pages/Help/FlutterInstall';
import ScrollToTop from './components/PageComponents/ScrollToTop';
import { Helmet } from 'react-helmet';
import EventRsvp from './components/Pages/EventRsvp';
import Blog from './components/Pages/Blog';
import InterestForm from './components/Pages/InterestForm';
import UpcomingEventBanner from './components/PageComponents/UpcomingEventBanner';
import BlogRedirects from './components/PageComponents/BlogRedirects';
import BlogAddTitle from './components/PageComponents/BlogAddTitle';
import Button from './components/PageComponents/Button';

class App extends Component {
  render() {
    const theme = createMuiTheme({
      typography: {
        fontFamily: `Google Sans, sans-serif`
      },
      palette: {
        primary: {
          main: '#4285F4',
          dark: '#1967d2',
          light: '#d2e3fc',
          contrastText: '#fff'
        },
        secondary: {
          main: '#4285F4',
          dark: '#1967d2',
          light: '#d2e3fc',
          contrastText: '#fff'
        },
      }
    })
    return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAU Google Developer Student Club</title>
        </Helmet>
        <BrowserRouter>
          <ScrollToTop>
            <div className="App container full-height-grow">
              <UpcomingEventBanner />
              <Navbar />
              <Switch>
                <Route exact path="/" component={Home} />

                {/* redirects: */}
                {Redirects.map(redirect => {
                  return (
                    (redirect.to.startsWith("https://") || redirect.to.startsWith("http://")) ? (
                      <Route key={redirect.from} exact path={redirect.from} component={() => {
                        window.location.replace(redirect.to);
                        return (
                          <div>
                            <h1 style={{ textAlign: 'center' }}>Redirecting...</h1>
                            <p style={{ textAlign: 'center' }}><a href={redirect.to}>Not working? Click here instead.</a></p>
                          </div>
                        );
                      }} />
                    ) : (

                      <Route key={redirect.from} exact path={redirect.from}>
                        <Redirect to={redirect.to} />
                      </Route>)
                  )
                })}

                <Route exact path="/events/sc" component={() => {
                  window.open("https://go.dscfau.com/2021sc", "_blank");
                  return (
                    <div>
                      <h1 style={{ textAlign: 'center' }}>Opening the 2021 Solution Challenge website...</h1>
                      <p style={{ textAlign: 'center' }}><a target="_blank" rel="noreferrer" href="https://go.dscfau.com/2021sc">Not working? Click here instead.</a></p>
                      <p style={{ textAlign: 'center' }}><Button useLink href="/">Go to our home page</Button></p>
                    </div>
                  );
                }} />

                <Route exact path="/about" component={About} />
                <Route exact path="/events" component={Events} />
                <Route exact path="/events/:event" component={Event} />
                <Route exact path="/events/:event/rsvp" component={EventRsvp} />

                <Route exact path="/blog" component={Blog} />
                <Route exact path="/blog/:blogPost/:name?" component={BlogAddTitle} />
                {/* <Route exact path="/blog/:blogPost/:name?" component={BlogPost} /> */}

                <Route exact path="/blogs/:blogPost?/:name?" component={BlogRedirects} />
                <Route exact path="/b/:blogPost?/:name?" component={BlogRedirects} />
                {/* <Route exact path="/blog/:blogPost/:name?" />
                <Route exact path="/blog" component={BlogPost} /> */}

                <Route exact path="/interest" component={InterestForm} />

                <Route exact path="/help/flutter-install" component={FlutterInstall} />

                <Route path="*" component={NotFound} />

              </Switch>
            </div>
            <Footer />
            <PageCircles />
          </ScrollToTop>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;