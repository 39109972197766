import csj1 from '../images/events/csj1.png';
import csj2 from '../images/events/csj2.png';
import csj3 from '../images/events/csj3.png';
import csj4 from '../images/events/csj4.png';
import cloudshell from '../images/events/cloudshell.png';
import compute from '../images/events/compute.png';
// import gke from '../images/events/gke.png';
import googleCloud from '../images/events/googlecloud.png';
import flutter from '../images/events/flutter.png';
import git from '../images/events/git.png';
import python from '../images/events/python.png';
import dscDefault from '../images/events/dsc-default.png';
import { Link } from 'react-router-dom';
import django from '../images/events/dj192.png';
import tensorflow from '../images/events/tensorflow_event_icon_new.png';
import functions from '../images/events/Cloud Functions.svg';
import pongT from '../images/events/pong-t.png';
import regex from '../images/events/regex.png';
import hackapalooza from '../images/events/hackapalooza.png';
import competitiveProgramming from '../images/events/competitive.png';
import neuralNetworks from '../images/events/howneuralnetworkslearn.png';
import research from '../images/events/research.png';
import research2 from '../images/events/research2.png';
import android1 from '../images/events/android-wk1.png';
import pdd from '../images/events/pdd.png';
import generators from '../images/events/generators.png';
import tfGde from '../images/events/tf-ws-gde.png';
import aiHurricanes from '../images/events/ai-hurricanes.jpg';

// import { WarningRounded } from '@material-ui/icons'

/*
    sections:
        STUDY_JAM
        NEED_HELP
*/
/*
 * Note: Paragraphs can be either strings (quotes) OR html (no quotes).
 *
 * IMPORTANT: If using links (<a> tags): If the link is to a page on the website (such as the home page), use
 *   the <Link> tag instead:
 *     <a href="/events">Events Page</a> ---> <Link to="/events">Events page</Link>
 *   (Note the "to" instead of "href")
 *   This loads faster for the user and does not involve an additional network request.
 */

/* RSVP LINKS
 * By default, RSVP Links are hidden if the event date has passed.
 * Use "rsvpLink: null" if there is no link.
 * 
 * Use "forceShowRSVP: true" to force show the RSVP link even after the event has passed.
 * Use "forceShowRSVP: false" to force hide the RSVP link regardless of the date.
 * Use "forceShowRSVP: null" to only show the link on or before the event date.
 * 
 */

/* Note: Order matters
 * 
 * Events are placed on the "Events" page in the order listed here
 * (top event is top)
 */

// Hiding events with "hide: true" only hides them from the events page.

/**
 * @typedef {Object} EventSep
 * @property {string} sep - Name of the separator (as it should appear)
 * @property {string} id - a unique identifier for hash links
 */

/**
 * @typedef {Object} EventSection
 * @property {string} title - The title of the event section
 * @property {Array<*>} paragraphs - The paragraphs within the section. Use JSX, include <p> or <div> tags.
 */

/**
 * @typedef {Object} PredefinedEventSection
 * @property {'STUDY_JAM'|'NEED_HELP'} predefined - a predefined event section
 */

/**
 * @typedef {object} EventRsvpInfo - different property values depending on the type
 * @property {boolean} isOpen - Whether the RSVP form is currently opened
 * @property {'external'|'gform'} type - the type of rsvp form used
 * @property {string} [formId] - Google Form ID - only use for google forms
 * @property {string} [url] - URL to redirect to (use for everything else)
 * @property {boolean} forceShow - Whether to force show the RSVP button (even after the event has passed)
 */

/**
 * @typedef {Object} Event
 * @property {string} name - Name of the event
 * @property {Array<string>} type - Array of event types (show below name)
 * @property {boolean} [series] - Whether the event is a series
 * @property {string} [parent] - If it's a series, the parent of the individual events
 * @property {string} date - Date text to show on event page
 * @property {boolean} hideFromList - whether to hide form the main event list page
 * @property {boolean} hideFromUpcoming - whether to hide from the upcoming events page
 * @property {string} time - Time text to show on event page
 * @property {boolean} forceShowTime - Whether to force show the time of the event (even after the event has passed)
 * @property {boolean} forceShowRSVP - Whether to force show the RSVP button (even after the event has passed)
 * @property {EventRsvpInfo} rsvp
 * @property {string} image - The image for the evnet (use import)
 * @property {string} url - The url component AFTER /events/
 * @property {boolean} showVideo - whether to show the video or not
 * @property {string} videoId - the YouTube video ID of the event
 * @property {string} videoImageUrl - the url to the video thumbnail
 * @property {*} [seriesSummary] - Optional summary to display on the event series (if applicable)
 * @property {*} summary - The summary of the event (shows on /events page). May use JSX (html tags, one root only)
 * @property {Array<EventSection>} sections - The sections on the event page.
 */

/**
 * @type {Array<Event|EventSep>}
 */
const events = [
    // {
    //     sep: 'Upcoming Events',
    //     id: "upcoming",
    // },
    {
        sep: 'Upcoming Fall 2022 Events',
        id: "upcoming-fa22",
    },
    {
        name: "Artificial Intelligence in Hurricane Prediction",
        type: ["Workshop"],
        hideFromList: null,
        date: "October 14th, 2022",
        time: "11:00am - 12:30pm EST",
        image: aiHurricanes,
        url: 'ai-hurricanes',
        rsvp: {
            type: 'external',
            url: 'https://go.dscfau.com/rsvp/ai-hurricanes',
            isOpen: null,
            forceShow: null,
        },
        summary: <p>Come join us Friday, October 14th for an exciting session about the use of AI in Hurricane Prediction models 🌀 💨 🌪.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Come join us Friday, October 14th for an exciting session about the use of AI in Hurricane Prediction models 🌀 💨 🌪.</p>,
                    <p>Learn how to use AI models to predict extreme weather conditions with our GDSC FAU President, Ali Altaher!</p>,
                ]
            },
        ]
    },
    {
        sep: 'Recent Events',
        id: "recent"
    },
    {
        name: "TensorFlow Workshop with a GDE",
        type: ["Workshop"],
        hideFromList: null,
        date: "June 25th, 2022",
        time: "1:00pm - 3:00pm EST",
        image: tfGde,
        url: 'tensorflow-workshop',
        rsvp: {
            type: 'external',
            url: 'https://go.dscfau.com/c/tf-rsvp',
            isOpen: null,
            forceShow: null,
        },
        summary: <p>Join us Saturday, June 25th (1pm - 3pm) to learn how to train a model to make predictions from 2D data! GDE Henry Ruiz will walk us through using TensorFlow.js to use TensorFlow in web browsers.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us Saturday, June 25th (1pm - 3pm) to learn how to train a model to make predictions from 2D data!</p>,
                    <p>PLUS we'll be having GDE Expert Henry Ruiz to walk us through! 🎉</p>,
                    <p>GDE Henry Ruiz will walk us through using TensorFlow.js to use TensorFlow in web browsers.</p>
                ]
            },
        ]
    },
    {
        sep: 'Spring 2022 Events',
        id: "sp22-events",
    },
    {
        name: "Android Study Jam Week 1",
        type: ["Study Jam", "Solution Challenge"],
        hideFromList: null,
        date: "March 9th, 2022",
        time: "7:00pm - 8:00pm EST",
        image: android1,
        url: 'android',
        hideFromUpcoming: true,
        rsvp: {
            type: 'external',
            url: 'https://gdsc.community.dev/events/details/developer-student-clubs-florida-atlantic-university-presents-android-study-jam-2022-03-09/',
            isOpen: null,
            forceShow: null,
        },
        summary: <p>Join students from across the US and Canada in this Android Study Jam series taught by Google Developer Expert Sierra Obryan and fellow Google Developer Experts  who explore the foundations of Android  mobile app development.<br /><br />
            Earn badges, swag, & the opportunity to help build your projects for this year’s Solution Challenge!<br /><br />
            Every Wednesday March 9th to March 30th; 7pm EST.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Week 1: Learn to code in Kotlin, a modern programming language that makes coding clear and accessible.<br />
                        <span style={{ marginLeft: "20px" }}>Sierra Obryan & Inaki Villar</span><br />
                        <span style={{ marginLeft: "20px" }}>Co-hosting school: GDSC University of Illinois</span>
                    </p>,
                    <p>Join students from across the US and Canada in this Android Study Jam series taught by Google Developer Expert Sierra Obryan and fellow Google Developer Experts who explore the foundations of Android mobile app development. This gives students the opportunity to help build their projects for this year’s Solution Challenge!</p>,
                    <p>This series provides an overview of the fundamentals to develop a mobile app. Students will come out understanding how to code in Kotlin, how to use Android Studio, basic app design, and interactive features! </p>,
                    <p>Have the opportunity to earn badges, construct an app, and win Android swag!</p>,
                    <p>Every Wednesday March 9th to March 30th; 7pm EST.</p>,
                    <p>Optional: App submissions due April 6th to win special swag!</p>,

                ]
            },
            // {
            //     title: "Live Stream",
            //     paragraphs: [
            //         <p>
            //             On March 9th at 7:00pm, join us on YouTube Live:
            //             <br />
            //             <a href="https://youtu.be/GtPnTJ9yZ2Y">https://youtu.be/GtPnTJ9yZ2Y</a>
            //         </p>
            //     ]
            // }
        ]
    },
    {
        name: "Intro to Python Generators",
        type: ["Workshop"],
        hideFromList: null,
        date: "March 15th, 2022",
        time: "5:30pm - 6:30pm EST",
        image: generators,
        url: 'generators',
        rsvp: {
            type: 'external',
            url: 'https://forms.gle/As4FZRnRWpNMyXio8',
            isOpen: null,
            forceShow: null,
        },
        summary: <p>Join us on Tuesday, March 15th, 2022 at 5:30pm for a hands-on Python workshop using Python generators to learn about linear congruential number generators & create a number generator that will be an infinite generator yielding a newly calculated random value for each iteration!</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us on Tuesday, March 15th, 2022 at 5:30pm for a hands-on Python workshop using Python generators to learn about linear congruential number generators & create a number generator that will be an infinite generator yielding a newly calculated random value for each iteration!</p>,
                    <p>This workshop will take place from 5:30pm - 6:30pm on Tuesday, March 15th in-person in room EE207 & Zoom.
                        Save the date!</p>
                ]
            },
            // {
            //     title: "Zoom Information",
            //     paragraphs: [
            //         <p>
            //             On March 15th at 5:30pm, you can join us in EE207 or through Zoom:
            //             <br />
            //             <a href="https://fau-edu.zoom.us/j/88095817444?pwd=VVhHaTdtNE1NQWdJYVlTQUkrSVF1QT09">https://fau-edu.zoom.us/j/88095817444?pwd=VVhHaTdtNE1NQWdJYVlTQUkrSVF1QT09</a>
            //             <br /><br />
            //             Meeting ID: <code>880 9581 7444</code><br />
            //             Passcode: <code>6e4hkJ</code>
            //         </p>
            //     ]
            // }
        ]
    },
    {
        name: "Professional Development Day",
        type: ["Workshop"],
        hideFromList: null,
        date: "March 19th, 2022",
        time: "10:00am - 3:00pm EST",
        image: pdd,
        url: 'pdd',
        rsvp: {
            type: 'external',
            url: 'https://forms.gle/K1nKeAPNafbgjfwdA',
            isOpen: null,
            forceShow: null,
        },
        summary: <p>Join us on Saturday, March 19th from 10am to 3pm in Engineering East's Gangal Hall for a professional development day consisting of professional guest speakers, mock interviews, resume critiquing, and networking opportunities.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us on Saturday, March 19th from 10am to 3pm in Engineering East's Gangal Hall for a professional development day consisting of professional guest speakers, mock interviews, resume critiquing, and networking opportunities.</p>,
                    <p>This event is hosted in collaboration with GDSC (Google Developer Student Club), TBP (Tau Beta Pi), SWE (Society of Women Engineers), NSBE (National Society of Black Engineers), ASCE (American Society of Civil Engineers), SHPE (Society of Hispanic Professional Engineers), and ASME (American Society of Mechanical Engineers).</p>
                ]
            },
            {
                title: "Important",
                paragraphs: [
                    <p>
                        This event is taking place in-person at Engineering East on March 19th, 10am-3pm.
                        <br /><br />
                        Please RSVP using the link above.
                    </p>
                ]
            }
        ]
    },

    {
        name: 'Solution Challenge Info Session',
        type: ['Solution Challenge Session'],
        date: "February 23rd, 2022",
        time: "5:00pm - 6:00 pm",
        image: dscDefault,
        url: 'sc-info-feb-23',
        summary: <p>
            Our special guest from this week's quick meeting will be joining us virtually to discuss
            more about the solution challenge and learn more of what is to come! This will cover a more in-depth of how to participate in this year's solution challenge! Giveaway included!!
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Our special guest from this week's quick meeting will be joining us virtually to discuss
                        more about the solution challenge and learn more of what is to come! This will cover a more in-depth of how to participate in this year's solution challenge! Giveaway included!!</p>
                ]
            }
        ]
    },
    {
        name: 'General Body Meeting',
        type: ['General Body Meeting'],
        date: "February 9th, 2022",
        time: "5:30pm - 6:30 pm",
        image: dscDefault,
        url: 'feb9-22-gbm',
        rsvp: {
            type: 'external',
            url: 'https://forms.gle/cgwQkvzh95YGp2ScA',
            isOpen: false,
            forceShow: null,
        },
        summary: <p>
            Join us THIS Wednesday as we go over how to get involved at GDSC and meet our officers!<br /><br />
            We will talk about the Google Solution Challenge 2022, our upcoming events, initiatives, and officer applications!
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us THIS Wednesday as we go over how to get involved at GDSC and meet our officers!</p>,
                    <p>We will talk about the Google Solution Challenge 2022, our upcoming events, initiatives, and officer applications!</p>,
                    <p>Get to know one another and prizes will be included!</p>,
                    <p>
                        When: <strong>Wednesday, February 9th, 2022</strong><br />
                        Time: <strong>5:30 pm - 6:30 pm EST</strong><br />
                        Where: <strong>Room EE106 (Engineering East Room 106)</strong>
                    </p>

                ]
            }
        ]
    },
    {
        name: "Introduction to Research Day 2",
        type: ["Research Series"],
        hideFromList: null,
        // parent: 'research',
        date: "February 1st, 2022",
        time: "5:30 PM - 6:30 PM (EST)",
        image: research2,
        url: 'research2',
        showVideo: false,
        videoId: null,
        videoImageUrl: null,
        summary: <p>Want to know how to build your ideas into research projects? What it takes to develop a research project?
            <br /><br />
            Join us as we discuss the Introduction to Research and provide you with the opportunities to build your own ideas into tangible research.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Want to know how to build your ideas into research projects? What it takes to develop a research project?
                    </p>,
                    <p>
                        Introducing Research Group of GDSC FAU!
                    </p>,
                    <p>
                        Join us Tuesday, February 1st as we discuss the Introduction to Research and provide you with the opportunities to build your own ideas into tangible research.
                    </p>,
                    <p>
                        When: Tuesday, February 1st @ 5:30-6:30 PM EST
                    </p>,
                    <p>
                        Where: Room EE207 @ Engineering East & Zoom
                    </p>,
                ]
            }, {
                title: "How to join",
                paragraphs: [
                    <p>This event is available in-person (in EE 207) and virtually (over Zoom).
                        You must a current FAU Student to attend.</p>,
                    <p>This event will take place on Tuesday, February 1st, 2022 from 5:30 pm until 6:30 pm (all times EST).</p>,
                    <p></p>
                ]
            },
        ]
    },
    {
        name: "Introduction to Research Day 1",
        type: ["Research Series"],
        hideFromList: null,
        // parent: 'research',
        date: "January 27th, 2022",
        time: "5:30 PM - 6:30 PM (EST)",
        image: research,
        url: 'research1',
        rsvp: {
            type: 'external',
            url: 'https://fau.campuslabs.com/engage/event/7753859',
            isOpen: true,
            forceShow: null,
        },
        showVideo: false,
        videoId: null,
        videoImageUrl: null,
        summary: <p>Want to know how to build your ideas into research projects? What it takes to develop a research project?
            <br /><br />
            Join us as we discuss the Introduction to Research and provide you with the opportunities to build your own ideas into tangible research.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Want to know how to build your ideas into research projects? What it takes to develop a research project?
                    </p>,
                    <p>
                        Introducing Research Group of GDSC FAU!
                    </p>,
                    <p>
                        Join us Thursday, January 27th as we discuss the Introduction to Research and provide you with the opportunities to build your own ideas into tangible research.
                    </p>,
                    <p>
                        When: Thursday, January 27th @ 5:30-6:30 PM EST
                    </p>,
                    <p>
                        Where: Room EE207 @ Engineering East
                    </p>,
                ]
            }, {
                title: "How to join",
                paragraphs: [
                    <p>This event is available in-person (in EE 207) and virtually (over Zoom).
                        You must a current FAU Student to attend.</p>,
                    <p>This event will take place on Thursday, January 27th, 2022 from 5:30 pm until 6:30 pm (all times EST).</p>,
                    <p></p>
                ]
            }, {
                title: "Zoom Information",
                paragraphs: [
                    <p>
                        On January 27th at 5:30 PM, you can join the session below:
                        <br />
                        <a className="blue_link" target="_blank" rel="noreferrer" href="https://fau-edu.zoom.us/j/83098165427?pwd=STNrRC9Geit5NU4rb3h6MHNWU1pldz09">https://fau-edu.zoom.us/j/83098165427?pwd=STNrRC9Geit5NU4rb3h6MHNWU1pldz09</a>
                        <br /><br />
                        Meeting ID: <code>830 9816 5427</code><br />
                        Passcode: <code>G7FRzq</code>
                        <br /><br />
                        <em>Note: You must be an FAU Student to join the Zoom call.</em>
                    </p>
                ]
            }, {
                title: "COVID Statement",
                paragraphs: [
                    <p>Attending in-person events such as this one has an inherent risk of contracting COVID-19.</p>,
                    <p><strong>If you are sick or experiencing symptoms of COVID-19, do not attend our in-person event and instead join us virtually over Zoom.</strong> If you are at risk of contracting COVID-19 due to other circumstances such as age or other underlying medical conditions, you may want to consider joining virtually instead of in-person.</p>,
                    <p><strong><em>All in-person attendees are expected to wear face coverings</em></strong> during the entirety of the event, regardless of vaccination status.</p>
                ]
            },
        ]
    },

    {
        name: "How Neural Networks Learn",
        type: ["Hands-on Workshop"],
        hideFromList: null,
        date: "January 11th, 2022",
        time: "5:30 PM - 6:30 PM (EST)",
        image: neuralNetworks,
        url: 'neuralnetworks',
        rsvp: {
            type: 'external',
            url: 'https://forms.gle/pAufhHQ7tyt4qrZV7',
            isOpen: false,
            forceShow: null,
        },
        showVideo: false,
        videoId: null,
        videoImageUrl: null,
        summary: <p>Learn the math that powers modern AI!
            <br /><br />
            Join us as we learn the fundamental math and statistics which powers modern self-driving cars, facial recognition, and text generation technologies.</p>,
        sections: [
            {
                title: "Learn the math that powers modern AI!",
                paragraphs: [
                    <p>
                        Want to learn what underlies deep learning?
                    </p>,
                    <p>
                        Join us as we learn the fundamental math and statistics which powers modern self-driving cars, facial recognition, and text generation technologies.
                    </p>,
                    <p>
                        Knowledge of Calculus 1 is strongly recommended!
                    </p>,
                ]
            }, {
                title: "How to join",
                paragraphs: [
                    <p>This event is available in-person (in EE 106) and virtually (over Zoom).
                        You must a current FAU Student to attend in person. </p>,
                    <p>This event will take place on Tuesday, January 11th, 2022 from 5:30 pm until 6:30 pm (all times EST).</p>,
                    <p>A Zoom link will be provided to those who RSVP for the event.</p>
                ]
                // }, {
                //     title: <><WarningRounded style={{ verticalAlign: 'middle', marginBottom: '2px' }} /> Important Information <WarningRounded style={{ verticalAlign: 'middle', marginBottom: '2px' }} /></>,
                //     paragraphs: [
                //         <p>NOTICE: This event is in the process of being approved by Student Union, which might have this event be postponed if it is NOT approved</p>
                //     ]
            }, /*{
                title: "COVID Statement",
                paragraphs: [
                    <p>Attending in-person events such as this "How Neural Networks Learn" event has an inherent risk of contracting COVID-19.</p>,
                    <p><strong>If you are sick or experiencing symptoms of COVID-19, do not attend our in-person event and instead join us virtually over Zoom.</strong> If you are at risk of contracting COVID-19 due to other circumstances such as age or other underlying medical conditions, you may want to consider joining virtually instead of in-person.</p>,
                    <p><strong><em>All in-person attendees are expected to wear face coverings</em></strong> during the entirety of the event, regardless of vaccination status.</p>
                ]
            },*/
        ]
    },
    {
        sep: 'Fall 2021 Events',
        id: "fa21-events",
    },
    {
        name: "Build a Discord Bot",
        type: ["Hands-on Workshop"],
        hideFromList: null,
        date: "November 29th, 2021",
        time: "5:30 PM (EST)",
        image: dscDefault,
        url: 'discordbot',
        rsvp: {
            type: 'external',
            url: 'https://fau.campuslabs.com/engage/event/7636468',
            isOpen: false,
            forceShow: null,
        },
        showVideo: false,
        videoId: null,
        videoImageUrl: null,
        summary: <p>Interested in building a Discord bot?
            <br /><br />
            Get the opportunity to build your very own Discord bot in this hands-on workshop. Learn how APIs work and how to interact with them, understand how the Discord API is structured, and follow along to build a chat bot using Discord!</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Love using Discord? Want to develop a bot? How about both!
                    </p>,
                    <p>
                        Get the opportunity to build your very own Discord bot in this hands-on workshop. Learn how APIs work and how to interact with them, understand how the Discord API is structured, and follow along to build a chat bot using everyone's favorite chat and voice application (or at least our favorite).
                    </p>,
                    <p>
                        We'll be using <a href="https://discord.js.org/" rel="noreferrer" target="_blank">discord.js</a>. Node.js version 16 or later is required for the latest version of discord.js.
                    </p>
                ]
            },
        ]
    },
    {
        name: "Hackapalooza",
        type: ["Hackathon"],
        hideFromList: null,
        date: "September 25th, 2021",
        time: "12:00pm until Sept. 26, 5:00pm",
        image: hackapalooza,
        url: 'hackapalooza',
        rsvp: {
            type: 'external',
            url: 'https://register.hackapalooza.dev',
            isOpen: false,
            forceShow: null,
        },
        showVideo: false,
        videoId: null,
        videoImageUrl: null,
        summary: <p>Want to learn how to read and write gibberish?
            <br /><br />Join our Tech Lead Seth Goldin as the FAU Google Developer Student Club discusses Regex, an essential tool for any programmer's skill-set. </p>,
        sections: [
            {
                title: "Unleash Your Creativity",
                paragraphs: [
                    <p>
                        The FAU Google Developer Student Club is co-hosting a hackathon on September 25th and 26th, focused on bringing students together through creative but unnecessary projects and technical workshops.
                    </p>,
                    <p>
                        Build the project you never knew you needed, or never thought you'd get the opportunity to hack!
                    </p>,
                ]
            },
            {
                title: "More information",
                paragraphs: [
                    <p>
                        To learn more about Hackapalooza, visit <a className="blue_link" href="https://hackapalooza.dev" target="_blank" rel="noreferrer">hackapalooza.dev</a>
                    </p>,
                    <p>
                        Our primary method of communication and interaction will be through Discord.
                        <br />
                        Join the Discord server at <a className="blue_link" href="https://discord.gg/gt5nKGbMNy" target="_blank" rel="noreferrer">discord.gg/gt5nKGbMNy</a>
                    </p>,
                    <p>
                        Register for Hackapalooza at <a className="blue_link" href="https://register.hackapalooza.dev" target="_blank" rel="noreferrer">register.hackapalooza.dev</a>
                    </p>
                ]
            },
        ]
    },
    {
        name: "Intro to Competitive Programming",
        type: ["Intro Session"],
        hideFromList: null,
        date: "September 16th, 2021",
        time: "5:30 PM (EST)",
        image: competitiveProgramming,
        url: 'competitiveprogramming',
        rsvp: {
            type: 'external',
            url: 'https://fau.campuslabs.com/engage/event/7353858',
            isOpen: true,
            forceShow: null,
        },
        showVideo: false,
        videoId: null,
        videoImageUrl: null,
        summary: <p>Interested in learning about Competitive Programming?
            <br /><br />
            Join us Thursday, September 16th @ 5:30 PM to learn what Competitive Programming is and how you can get involved. This event will be hosted by Dr. Feng-Hao Liu. Dr. Feng-Hao Liu has won national level programming competitions and is interested in starting his own team to participate in ACM ICPC regional contest. </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Interested in learning about Competitive Programming?
                    </p>,
                    <p>
                        Join us Thursday, September 16th @ 5:30 PM to learn what Competitive Programming is and how you can get involved. This event will be hosted by Dr. Feng-Hao Liu. Dr. Feng-Hao Liu has won national level programming competitions and is interested in starting his own team to participate in ACM ICPC regional contest.
                    </p>,
                ]
            },
            {
                title: "Zoom Information",
                paragraphs: [
                    <p>
                        On September 16th at 5:30 PM, you can join the session below:
                        <br />
                        <a className="blue_link" target="_blank" rel="noreferrer" href="https://fau-edu.zoom.us/j/88329140089?pwd=YXlVWEVMc3VIYXREMEZ0UW9FcDF6UT09">https://fau-edu.zoom.us/j/88329140089?pwd=YXlVWEVMc3VIYXREMEZ0UW9FcDF6UT09</a>
                        <br /><br />
                        Meeting ID: <code>883 2914 0089</code><br />
                        Passcode: <code>a56RVR</code>
                        <br /><br />
                        <em>Note: You must be an FAU Student to join the Zoom call.</em>
                    </p>
                ]
            }
        ]
    },
    {
        name: "Fall 2021 Info Session",
        type: ["Info Session"],
        hideFromList: null,
        date: "September 2nd, 2021",
        time: "5:00 PM (EST)",
        image: dscDefault,
        url: 'info',
        rsvp: {
            type: 'external',
            url: 'https://gdsc.community.dev/e/m52df3/',
            isOpen: false,
            forceShow: null,
        },
        showVideo: false,
        videoId: null,
        videoImageUrl: null,
        summary: <p>Join the FAU Google Developer Student Club in this Fall 2021 info session and general body meeting.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Join the FAU Google Developer Student Club in this info session and general body meeting as we:
                    </p>,
                    <ul>
                        <li>introduce the Google Developer Student Club at Florida Atlantic University</li>
                        <li>explain how you can join and why you should</li>
                        <li>announce upcoming and planned events & how GDSC FAU can help build with you</li>
                        <li>discuss opportunities to apply for leadership roles within the club</li>
                    </ul>,
                ]
            },
            {
                title: "Zoom Information",
                paragraphs: [
                    <p>
                        On September 2nd at 5:00pm, you can join the session below:
                        <br />
                        <a className="blue_link" target="_blank" rel="noreferrer" href="https://fau-edu.zoom.us/j/82449974121?pwd=bTJiRTlkQlpzN2dtckJMdFBjNzFKUT09">https://fau-edu.zoom.us/j/82449974121?pwd=bTJiRTlkQlpzN2dtckJMdFBjNzFKUT09</a>
                        <br /><br />
                        Meeting ID: <code>824 4997 4121</code><br />
                        Passcode: <code>cKHTi8</code>
                    </p>
                ]
            }
        ]
    },
    {
        sep: 'Summer 2021 Events',
        id: "su21-events",
    },
    {
        name: "Rock with Regex",
        type: ["Workshop"],
        hideFromList: null,
        date: "July 26th, 2021",
        time: "5:00pm - 6:00pm Eastern",
        image: regex,
        url: 'regex',
        rsvp: {
            type: 'external',
            url: 'https://forms.gle/dnNr5mSStZeDyRWY7',
            isOpen: false,
            forceShow: null,
        },
        showVideo: true,
        videoId: "Ql2uowN4oTU",
        videoImageUrl: 'https://img.youtube.com/vi/Ql2uowN4oTU/maxresdefault.jpg',
        summary: <p>Want to learn how to read and write gibberish?
            <br /><br />Join our Tech Lead Seth Goldin as the FAU Google Developer Student Club discusses Regex, an essential tool for any programmer's skill-set. </p>,
        sections: [
            {
                title: "Want to learn gibberish?",
                paragraphs: [
                    <p>Want to learn how to read and write gibberish?</p>,
                    <p>Join our Tech Lead Seth Goldin as the FAU Google Developer Student Club discusses Regex, an essential tool for any programmer's skill-set.</p>,
                    <p>Regex is commonly used to search or filter strings for information, input validation, and web scraping. While incredibly powerful, it's also often intimidating to learn and difficult to remember due to being seemingly unintelligible. In this event, you'll become a pro!</p>,
                    <p>This workshop will take place from <strong>5pm-6pm</strong> on <strong>Monday, July 26th</strong> via Zoom. Save the date!</p>,
                ]
            },
            // {
            //     title: "Zoom Information",
            //     paragraphs: [
            //         <p>
            //             On July 26th at 5:00pm, you can join the meeting below:
            //             <br />
            //             <a href="https://fau-edu.zoom.us/j/81942882287?pwd=YzB4bkVtZjI2czlOWFpaMVpXQkFpUT09">https://fau-edu.zoom.us/j/81942882287?pwd=YzB4bkVtZjI2czlOWFpaMVpXQkFpUT09</a>
            //             <br /><br />
            //             Meeting ID: <code>819 4288 2287</code><br />
            //             Passcode: <code>n0FWCs</code>
            //         </p>
            //     ]
            // }
        ]
    },
    {
        name: "Build Pong with Python",
        type: ["Workshop"],
        hideFromList: null,
        date: "July 1st, 2021",
        time: "5:30pm - 7:00pm Eastern",
        image: pongT,
        url: 'pong',
        rsvp: {
            type: 'external',
            url: 'https://forms.gle/KFiLv6JhEjiNMCzeA',
            isOpen: false,
            forceShow: null,
        },
        showVideo: false,
        summary: <p>Join and follow along as Aden Eagle, an FAU Student, discusses object-oriented Python programming and pygame and build your own Pong game.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Interested in getting started with object-oriented programming in Python or building games with pygame?</p>,
                    <p>Join us on July 1st to hear Aden Eagle discuss how to use object-oriented programming to store and manage data, functions, and state for your applications and pygame to simplify the creation of complex games with custom GUIs and easy handling of user input. After that, you’ll have the chance to follow Aden as he builds the Pong arcade game from scratch using Python, pygame, and object-oriented programming.</p>,
                ]
            },
            {
                title: "What will be covered",
                paragraphs: [
                    <ul>
                        <li>Python</li>
                        <li>Object-Oriented Programming</li>
                        <li>Pygame</li>
                    </ul>
                ]
            },
            {
                title: "What you'll learn",
                paragraphs: [
                    <ul className="learn_list">
                        <li>How to use object-oriented programming in Python</li>
                        <li>How to create movable GUI objects with pygame sprites</li>
                        <li>How to receive key presses in pygame to allow user interaction </li>
                        <li>How to detect collisions between pygame sprites and respond to them</li>
                    </ul>
                ]
            },
            {
                title: "In-Person and Virtual Attendance",
                paragraphs: [
                    <p>This event will take place virtually (on Zoom) and in-person at the FAU Boca Campus.</p>,
                    <p>In order to attend in-person, you must be a <em>current FAU student</em> (non-FAU students and alumni are not allowed to attend in-person).</p>,
                    <p><strong>To attend in-person, you must RSVP by June 28th, 11:59 pm and fill out this form <a href="https://forms.gle/m2DuPdP14fEZQpVb9" rel="noreferrer" target="_blank">https://forms.gle/m2DuPdP14fEZQpVb9</a> by June 28th, 11:59pm.</strong></p>,
                    <p>FAU is still operating at 25% maximum occupancy for in-person gatherings, so we will limit the number of students that can attend in person. If you show up in person without registering or after we reach our maximum capacity, you will not be admitted. We will email you prior to the event whether you are able to attend in person.</p>,
                ]
            },
            {
                title: "No prior experience required!",
                paragraphs: [
                    <p>No prior experience of pygame required for this event.</p>,
                    <p>Some basic Python experience is recommended, but not required.</p>,
                    <p>Need a refresher or crash course on Python? Check out our <Link to="/events/python">Intro to Python series</Link>:</p>,
                    <ol>
                        <li><a href="https://youtu.be/OASWL-cp8Ss" rel="noreferrer" target="_blank">https://youtu.be/OASWL-cp8Ss</a> (part 1, 33 minutes) - Basic Python syntax, variables, functions, etc.</li>
                        <li><a href="https://youtu.be/HYARnISnppw" rel="noreferrer" target="_blank">https://youtu.be/HYARnISnppw</a> (part 2, 27 minutes) - Python classes, objects, modules, and more</li>
                    </ol>
                ]
            },
        ]
    },
    {
        sep: 'Spring 2021 Events',
        id: "spring2021",
    },
    {
        name: "Intro to Cloud Functions",
        type: ["Workshop"],
        hideFromList: null,
        date: "April 14th, 2021",
        time: "7:00pm - 8:00pm Eastern",
        image: functions,
        url: 'functions',
        rsvp: {
            type: 'external',
            url: 'https://dsc.community.dev/e/m646u3/',
            'isOpen': null,
            forceShow: null,
        },
        showVideo: false,
        summary: <p>Join us as we introduce Cloud Functions as serverless computing and build your very first Cloud Function!</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join the FAU Google Developer Student Club on Wednesday, April 14th, 2021, and follow along as we introduce you to <strong>Cloud Functions</strong> on Google Cloud.</p>,
                    <p>Cloud Functions on the Google Cloud Platform are a super powerful and easy way to set up simple or complex backend systems, capable of scaling up and down quickly, responding to background events, and acting as an HTTP web server using Express (Node.js), Flask (Python), and more!</p>,
                    <p>In this event, we’ll give you access to the Google Cloud Platform to build, test, and deploy your very first Cloud Function that responds to HTTP requests using Flask in Python.</p>,
                ]
            },
            {
                title: "What you'll learn",
                paragraphs: [
                    <ul className="learn_list">
                        <li>Why serverless computing is so appealing</li>
                        <li>How Cloud Functions make creating applications easy</li>
                        <li>How background events make Cloud Functions so powerful</li>
                        <li>How to write an HTTP Cloud Function using Flask</li>
                        <li>How to test an HTTP Cloud Function</li>
                    </ul>
                ]
            },
            {
                title: "No prior experience required!",
                paragraphs: [
                    <p>No prior experience of Cloud Functions, Flask, or cloud computing required for this event.</p>
                ]
            },
        ]
    },
    {
        name: "Intro to Tensorflow",
        type: ["Workshop"],
        hideFromList: null,
        date: "March 26th, 2021",
        time: "7:00 - 8:00pm",
        image: tensorflow,
        url: 'intro-to-tensorflow',
        showVideo: false,
        summary: <p>Join us as our Tech Lead John introduces machine learning and Tensorflow!</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Join us as our Tech Lead John introduces machine learning and Tensorflow. Follow along and build and test your very first machine learning model using Tensorflow.
                    </p>,
                ]
            },
            {
                title: "No prior experience required!",
                paragraphs: [
                    <p>No prior experience of TensorFlow or machine learning required for this event.</p>
                ]
            },
        ]
    },
    {
        name: "Django Part II - Python Weekly Series",
        type: ["Workshop", "Intro to Python"],
        parent: "python",
        hideFromList: null,
        date: "March 16th, 2021",
        time: "7:30 - 8:30pm",
        image: django,
        url: 'django2',
        rsvp: {
            type: 'external',
            url: 'https://fau.campuslabs.com/engage/event/6996983',
            'isOpen': null,
            forceShow: null,
        },
        showVideo: false,
        summary: <p>Join us as we continue with Django, a Python framework used to create web apps. <br /><br />This week, we'll discuss url mapping in Django and object relational mapping.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Join us as we continue with Django, a Python framework used to create web apps.
                    </p>,
                    <p>In this session, we'll go over url mapping in Django and introduce object relational mapping.</p>,
                ]
            },
            {
                title: "No prior experience required!",
                paragraphs: [
                    <p>No prior experience of Python or programming required for this event.</p>
                ]
            },
            {
                title: <a style={{ color: '#4285f4' }} target="_blank" rel="noreferrer" href="https://fau-edu.zoom.us/j/82175082078?pwd=Yy9KREhMRE9QbUtkY3JTcXF6L2N1dz09">Join Here: Zoom Link</a>,
                paragraphs: [
                    <p>To join this week's session, open this link at 7:30pm EST on March 16th: <a target="_blank" rel="noreferrer" href="https://fau-edu.zoom.us/j/82175082078?pwd=Yy9KREhMRE9QbUtkY3JTcXF6L2N1dz09" style={{ color: '#4285f4' }}>Django Part II Zoom Link</a></p>
                ]
            }
        ]
    },
    {
        name: "Django Part I - Python Weekly Series",
        type: ["Workshop", "Intro to Python"],
        parent: "python",
        hideFromList: null,
        date: "March 2nd, 2021",
        time: "7:30 - 8:30pm",
        image: django,
        url: 'django1',
        invalidrsvp: {
            type: 'external',
            url: '',
            'isOpen': false,
            forceShow: null,
        },
        showVideo: false,
        summary: <p>Join us as we introduce Django, a powerful framework in Python to create and run web applications. <br /><br />We'll introduce Django and set up a project, work with virtual environments, and use the Django admin dashboard.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Join us as we introduce Django, a powerful framework in Python to create and run web applications.
                    </p>,
                    <p>In this session, we'll help you set up a Django project, work with Python virtual environments, and use the Django Admin Dashboard for your web applications.</p>,
                ]
            },
            {
                title: "No prior experience required!",
                paragraphs: [
                    <p>No prior experience of Python or programming required for this event.</p>
                ]
            },
        ]
    },
    {
        name: "Intro to Python - Week 3",
        type: ["Workshop", "Intro to Python"],
        parent: "python",
        hideFromList: null,
        date: "February 23rd, 2021",
        time: "5:15 - 6:15pm",
        image: python,
        url: 'python3',
        invalidrsvp: {
            type: 'external',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSe16D3xzkWDYsObOHrE6NclAExWRyqTwbJd--309lJ93PJxpw/viewform',
            'isOpen': false,
            forceShow: null,
        },
        showVideo: false,
        summary: <p>Join us as we review the content discussed during the first two weeks. <br /><br />We'll introduce Python and go over Python syntax, variables, math, loops, conditions, and functions.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Join us as we review the content discussed during the first two weeks.
                    </p>,
                    <p>In this session, we'll introduce Python as a programming language and go over Python syntax, variables, math, loops, conditions, and functions. We'll also help you with troubleshooting installing python locally to your computer.</p>,
                ]
            },
            {
                title: "No prior experience required!",
                paragraphs: [
                    <p>No prior experience of Python or programming required for this event.</p>
                ]
            },
        ]
    },

    {
        name: 'February General Body Meeting',
        type: ['General Body Meeting', "Solution Challenge Intro and Group Forming"],
        date: "February 18th, 2021",
        time: "6:30pm - 7:30 pm",
        image: dscDefault,
        url: 'feb21-general-body-meeting',
        rsvp: {
            type: 'external',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSe_hpsy7u-09V4_Y05Be3c9uUFPHdzl-qf_VZ4XCzNDW5WcaA/viewform?usp=sf_link',
            'isOpen': false,
            forceShow: null,
        },
        summary: <p>
            Learn about what our club is offering over the next few weeks and learn about the 2021 Solution Challenge!
            <br /><br />
            Build projects in teams of up to four members to create a solution that addresses one or more of the United Nation's 17 Sustainable Development Goals (SDGs). In this session, learn how to get started and meet potential team members.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Learn about what our club is offering over the next few weeks and learn about the 2021 Solution Challenge!</p>
                    ,
                    <p>Build projects in teams of up to four members to create a solution that addresses one or more of the United Nation's 17 Sustainable Development Goals (SDGs). In this session, learn how to get started and meet potential team members.</p>
                ]
            }
        ]
    },
    {
        name: "Intro to Python - Week 2",
        type: ["Workshop", "Intro to Python"],
        parent: "python",
        // hideFromList: true,
        date: "February 16th, 2021",
        time: "5:15 - 6:15pm",
        image: python,
        videoId: "HYARnISnppw",
        videoImageUrl: "https://img.youtube.com/vi/HYARnISnppw/maxresdefault.jpg",
        url: 'python2',
        rsvp: {
            type: 'external',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSe16D3xzkWDYsObOHrE6NclAExWRyqTwbJd--309lJ93PJxpw/viewform',
            'isOpen': false,
            forceShow: null,
        },
        showVideo: true,
        summary: <p>Join us as we review last week's discussions and dive further into dictionaries, classes, objects, and modules in Python. At the end, we'll also walk you through building a sample Python project using what we've discussed so far!</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Have you ever been curious about how programming works but don't know how to get started? <br /><br />Join us at our intro to python tutorial where we will give a crash course on the basics in 30 minutes or less. Join us after for a breakout session where you will be able to speak with our experienced team of coders and start using what you learned right away.</p>,
                    <p>This week, join us as we review last week's discussions and dive further into dictionaries, classes, objects, and modules in Python. At the end, we'll also walk you through building a sample Python project using what we've discussed so far!</p>
                ]
            }
        ]
    },
    {
        name: "Intro to Python",
        type: ["Workshop", "Weekly Series"],
        date: "Weekly on Tuesdays",
        time: "5:15pm - 6:15pm EST",
        image: python,
        url: 'python',
        forceShowTime: true,
        series: true,
        showVideo: false,
        rsvp: {
            type: 'external',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSe16D3xzkWDYsObOHrE6NclAExWRyqTwbJd--309lJ93PJxpw/viewform',
            'isOpen': true,
            forceShow: false,
        },
        summary: <p>Have you ever been curious about how programming works but don't know how to get started? <br /><br />Join us at our intro to python tutorial where we will give a crash course on the basics in 30 minutes or less. Join us after for a breakout session where you will be able to speak with our experienced team of coders and start using what you learned right away.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Have you ever been curious about how programming works but don't know how to get started? <br /><br />Join us at our intro to python tutorial where we will give a crash course on the basics in 30 minutes or less. Join us after for a breakout session where you will be able to speak with our experienced team of coders and start using what you learned right away.</p>,
                ]
            },
            {
                title: "Where?",
                paragraphs: [
                    <p>Django Part II (Mar 23rd) will be hosted on Zoom. <a target="_blank" rel="noreferrer" href="https://fau-edu.zoom.us/j/82175082078?pwd=Yy9KREhMRE9QbUtkY3JTcXF6L2N1dz09">Please join here (Zoom)</a></p>
                ]
            },
            {
                title: "Topics",
                paragraphs: [
                    <ul>
                        <li><b>Feb 9</b> - Introduction to Python syntax, variables, math, loops, conditions, and functions</li>
                        <li><b>Feb 16</b> - Python dictionaries, classes, modules, and a sample project using Python</li>
                        <li><b>Feb 23</b> - Review of previous two weeks (intro to python, syntax, variables, math, loops, conditions, and functions</li>
                        <li><b>Mar 2</b> - Introduction to Django, setting up a Django project <em>Django Part I</em></li>
                        <li><b>Mar 16</b> <em>(7:30 pm - 8:30 pm)</em> - Working with Django, URL maps in Django, Object relational mapping <em>Django Part II</em></li>
                    </ul>
                ]
            },
            {
                predefined: "NEED_HELP"
            },
        ]
    },

    // {
    //     name: "Intro to Kubernetes Engine",
    //     type: ["Workshop", "Getting Started with Google Cloud"],
    //     date: 'TBD',
    //     // parent: 'get-started-with-gcp',
    //     time: "TBD",
    //     image: gke,
    //     // rsvp: {

    //     // },
    //     hideFromList: true,
    //     url: 'intro-to-kubernetes',
    //     summary: <p>Details to be announced soon</p>,
    //     sections: [
    //         {
    //             title: "Event Information",
    //             paragraphs: [<p>Details to be announced soon</p>,],
    //         }
    //     ]
    // },

    {
        name: "Intro to Python - Week 1",
        type: ["Workshop", "Intro to Python"],
        parent: "python",
        hideFromList: false,
        date: "February 9th, 2021",
        time: "5:15 - 6:15pm",
        image: python,
        showVideo: true,
        videoId: "OASWL-cp8Ss",
        videoImageUrl: "https://img.youtube.com/vi/OASWL-cp8Ss/maxresdefault.jpg",
        rsvp: {
            type: 'external',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSeyp1idj1p0C-Y_c_JNVtnuwrNpGrlXlXk6hGeUz9BYexmwYQ/viewform',
            'isOpen': false,
            forceShow: false,
        },
        url: 'python1',
        summary: <p>Join us as we introduce Python as a programming language and go over Python syntax, variables, math, loops, conditions, and functions.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Have you ever been curious about how programming works but don't know how to get started? <br /><br />Join us at our intro to python tutorial where we will give a crash course on the basics in 30 minutes or less. Join us after for a breakout session where you will be able to speak with our experienced team of coders and start using what you learned right away.</p>,
                    <p>This week, join us as we introduce Python as a programming language and go over Python syntax, variables, math, loops, conditions, and functions.</p>
                ]
            }
        ]
    },
    {
        name: "All About Git",
        type: ["Workshop"],
        date: 'January 28th, 2021',
        time: "6:30 PM - 7:30 PM EST",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSfDVDJ7UeFm6BGJ6lwh6Q_kg3feleBqSw7ob9isb3PEPWZ2eg/viewform?usp=sf_link',
            forceShow: null,
        },
        image: git,
        url: 'all-about-git',
        showVideo: false,
        summary: <p>Join our Tech Lead Seth as the FAU Google Developer Student Club discusses Git, a powerful version control tool, and how you can utilize it to power up your programming!</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join our Tech Lead Seth as the FAU Google Developer Student Club discusses Git, a powerful version control tool, and how you can utilize it to power up your programming!</p>,
                    <p>Git is an essential tool in any programmer's skillset that keeps a record of your work, drastically improves collaboration, and looks great on your resume.</p>,
                    <p>The Session will take place from 6:30-7:30 PM on Thursday, January 28th. Save the date!</p>,
                ]
            },
            {
                predefined: "NEED_HELP"
            },
        ]
    },

    {
        name: "Intro to Cloud Shell",
        type: ["Workshop", "Getting Started with Google Cloud"],
        date: 'January 23rd, 2021',
        parent: 'get-started-with-gcp',
        time: "2:30pm - 4:00pm Eastern",
        rsvp: {
            isOpen: false,
            // type: 'gform',
            type: 'external',
            formId: '1FAIpQLSceDBrJhz1TFJftw0kn4X22ssU1pzv4fgzmaYu9XALFezUwIQ',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSceDBrJhz1TFJftw0kn4X22ssU1pzv4fgzmaYu9XALFezUwIQ/viewform?usp=sf_link',
            forceShow: null,
            height: '2299px',
            // height: '750px',
        },
        image: cloudshell,
        url: "intro-to-cloud-shell",
        showVideo: false,
        videoId: '',
        videoImageUrl: '',
        seriesSummary: <p>
            In this session, we'll explore some of the powerful capabilities available through the Google Cloud Shell in GCP.
            You'll work with the pre-installed developer tools for creating and testing code, and utilize with the <em>gcloud</em> command-line tools to create and manage GCP resources in this hands-on event.</p>,
        summary: <p>
            Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the powerful capabilities available through the Google Cloud Shell in GCP.
            In this session, we'll introduce Cloud Shell, learn how to work with the pre-installed developer tools for creating and testing code, and work with the <em>gcloud</em> command-line tools to create and manage GCP resources.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the powerful capabilities available through the Google Cloud Shell in GCP.
                        In this session, we'll introduce Cloud Shell, learn how to work with the pre-installed developer tools for creating and testing code, and work with the <em>gcloud</em> command-line tools to create and manage GCP resources.
                        <br /><br />
                        There will a session from 2:30 pm to 3:00 pm to help attendees get access to the Qwiklabs platform. <strong>Please plan to attend this part if you did not attend part 2 or do not have access to Qwiklabs yet.</strong> No assistance will be provided for Qwiklabs access after 3:00 pm (unless agreed on beforehand).

                    </p>,
                ]
            },
            {
                title: "Prerequisites",
                paragraphs: [
                    <div>
                        <span>No prior Google Cloud experience required.</span>
                    </div>,
                    <div><br />
                        It may be benefical (but is not required) to watch or attend our <Link className="blue_link" to="/events/intro-to-google-cloud">previous event on Google Cloud</Link>.
                    </div>,
                    <div>
                        <br />
                        <span>
                            For this event, you'll need a working computer with a modern browser (such as <a rel="noreferrer" target="_blank" className="blue_link" href="https://www.google.com/chrome/">Google Chrome</a>) and a stable internet connection.
                        </span>
                        <br /><br />
                    </div>
                ]
            },
            {
                title: "What you'll learn",
                paragraphs: [
                    <ul className="learn_list">
                        <li>What Cloud Shell is in the Google Cloud Platform</li>
                        <li>How to utilize Cloud Shell to write and test code and applications</li>
                        <li>How zones and regions are organized in GCP</li>
                        <li>How to utilize Cloud Shell to create and manage GCP resources</li>
                        <li>How to work with the <em>gcloud</em> command-line tools.</li>
                    </ul>
                ]
            },
            {
                title: "What you'll gain in this series",
                paragraphs: [
                    <ul className="learn_list">
                        <li>Up to 60 days of free access to the Qwiklabs learning platform</li>
                        <li>Information on preparing for the Associate Cloud Engineer Certification</li>
                        <li>Hands-on experience with the Google Cloud Platform</li>
                        <li>Up to two skill badges from the Qwiklabs platform</li>
                    </ul>
                ]
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "Info Session",
        type: ["Info Session"],
        date: 'January 15th, 2021',
        time: "2:00pm - 3:00pm EST",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSeT9h_8gNc93dmTqYk5vQqVrU1MT5I_tU737c1LOdE3A9QwOw/viewform?usp=sf_link',
            forceShow: null,
        },
        image: dscDefault,
        url: 'info-spring-2021',
        showVideo: false,
        summary: <p>Learn about what Google Developer Student Clubs (GDSCs) are, how we try to make a difference in our community through hands-on, tech-oriented, educational workshops, and how you can become a GDSC member in our Florida Atlantic University GDSC chapter.</p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>In this event, we'll explain what GDSCs are, what we can offer to students to accelerate their technical careers, and how to join the club and stay up-to-date on our events.</p>,
                ]
            },
            {
                title: "What are GDSCs?",
                paragraphs: [
                    <p>
                        As a quick summary: Google Developer Student Clubs are student organizations sponsored and supported by Google Developers. Our mission is to help students bridge the gap between theory and practice through interactive, hands-on workshops featuring a wide range of the latest technologies used in the real world.
                    </p>
                ],
            },
            {
                title: "I'm Interested!",
                paragraphs: [
                    <p>
                        Awesome! The event will be hosted on Zoom and start at around 2 PM on Friday, January 15th.
                    </p>,
                    <p>
                        Please <a href="https://gdscfau.com/events/info/rsvp" rel="noreferrer" target="_blank">RSVP to this session</a>, and you'll get an email before the event with Zoom details.
                    </p>

                ],
            },
            {
                title: "Questions?",
                paragraphs: [
                    <p>
                        Have any questions? Feel free to email our officers at <a href="mailto:dscfau@gmail.com">dscfau@gmail.com</a>.
                    </p>,
                    <p>
                        Check out our club's Discord server to be reminded about events, discuss tech with fellow students, and ask our officers questions.
                    </p>

                ],
            },
        ]
    },
    {
        name: "Intro to Compute Engine",
        type: ["Workshop", "Getting Started with Google Cloud"],
        parent: 'get-started-with-gcp',
        date: 'December 23rd, 2020',
        time: "3:00pm - 5:00pm Eastern",
        rsvp: {
            isOpen: false,
            type: 'gform',
            formId: '1FAIpQLSfEfmXCTwrN4CT5VR_IdaZgGHcmfr1SLO74hu4G1nMc8OX1XQ',
            url: 'https://go.dscfau.com/rsvp/googlecloud2',
            forceShow: null,
            height: '760px',
        },
        image: compute,
        url: "intro-to-compute-engine",
        showVideo: false,
        videoId: 'R7d_MrAObG8',
        videoImageUrl: 'https://img.youtube.com/vi/R7d_MrAObG8/maxresdefault.jpg',
        seriesSummary: <p>
            Learn about Compute Engine, GCP zones and regions, and get to work with Google Compute Engine through a hands-on lab.
            <br />You'll create your own Compute Engine VM through the Console and through the CLI, and understand the different options and configurations available for instances.
        </p>,
        summary: <p>
            Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the fundamental
            concepts and APIs available in the Google Cloud Platform. In this session, we'll introduce Compute Engine, learn about GCP zones and regions, and work with Google Compute Engine through a hands-on lab.
            <br /><br />Learn how to create your own Compute Engine virtual machine through the Console and through the command line, and understand the different options and configurations available, including boot disks, operating systems, firewall rules and networking setup.
            You'll also learn how to configure a basic NGINX web server
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>
                        Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the fundamental
                        concepts and APIs available in the Google Cloud Platform. In this session, we'll introduce Compute Engine, learn about GCP zones and regions, and work with Google Compute Engine through a hands-on lab.
                        <br /><br />Learn how to create your own Compute Engine virtual machine through the Console and through the command line, and understand the different options and configurations available, including boot disks, operating systems, firewall rules and networking setup.
                        You'll also learn how to configure a basic NGINX web server
                    </p>
                ]
            },
            {
                title: "Prerequisites",
                paragraphs: [
                    <div>
                        <span>No prior Google Cloud experience required.</span>
                    </div>,
                    <div><br />
                        It may be benefical (but is not required) to watch or attend our <Link className="blue_link" to="/events/intro-to-google-cloud">previous event on Google Cloud</Link>.
                    </div>,
                    <div><br />
                        <span>For this event, you'll need a working computer with a modern browser (such as <a rel="noreferrer" target="_blank" className="blue_link" href="https://www.google.com/chrome/">Google Chrome</a>) and a stable internet connection.
                        </span><br /><br />
                    </div>
                ]
            },
            {
                title: "What you'll learn",
                paragraphs: [
                    <ul className="learn_list">
                        <li>What virtual machines are and how they work in cloud computing</li>
                        <li>Why cloud virtual machines are preferable to dedicated servers</li>
                        <li>How zones and regions are organized in GCP</li>
                        <li>How to create Compute Engine resources (such as virtual machines) through the GCP Console</li>
                        <li>How to create Compute Engine resources (such as virtual machines) through the Command Line</li>
                        <li>How to install a basic NGINX server and configure appropriate firewall rules</li>
                    </ul>
                ]
            },
            {
                title: "What you'll gain in this series",
                paragraphs: [
                    <ul className="learn_list">
                        <li>Up to 60 days of free access to the Qwiklabs learning platform</li>
                        <li>Information on preparing for the Associate Cloud Engineer Certification</li>
                        <li>Hands-on experience with the Google Cloud Platform</li>
                        <li>Up to two skill badges from the Qwiklabs platform</li>
                    </ul>
                ]
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "Intro to Google Cloud",
        type: ["Workshop", "Getting Started with Google Cloud"],
        parent: 'get-started-with-gcp',
        date: 'November 21st, 2020',
        time: "2:00pm - 3:30pm Eastern",
        forceShowRSVP: null,
        rsvpLink: "/rsvp/get-started-with-gcp",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: '/rsvp/get-started-with-gcp',
            forceShow: null,
        },
        image: googleCloud,
        url: "intro-to-google-cloud",
        showVideo: true,
        videoId: 'R7d_MrAObG8',
        videoImageUrl: 'https://img.youtube.com/vi/R7d_MrAObG8/maxresdefault.jpg',
        seriesSummary:
            <p>
                Follow along as we explore some of the fundamental concepts and APIs available in the Google Cloud Platform.
                Learn about the Qwiklabs platform (giving you real, hands-on practice) and the
                Google Cloud Platform Console, and then understand how you can advance your career with Google Cloud certifications.
            </p>
        ,
        summary: <p>
            Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the fundamental
            concepts and APIs available in the Google Cloud Platform. We'll introduce the Qwiklabs platform and the
            Google Cloud Platform Console, and then discuss how to advance your career with Google Cloud certifications.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the fundamental
                        concepts and APIs available in the Google Cloud Platform. We'll introduce the Qwiklabs platform and the
                        Google Cloud Platform Console, and then discuss how to advance your career with Google Cloud certifications.</p>,
                ]
            },
            {
                title: "Prerequisites",
                paragraphs: [
                    <div>
                        <span>No prior Google Cloud experience required.</span>
                    </div>,
                    <div><br />
                        <span>For this event, you'll need a working computer with a modern browser (such as <a rel="noreferrer" target="_blank" className="blue_link" href="https://www.google.com/chrome/">Google Chrome</a>) and a stable internet connection.
                        </span><br /><br />
                    </div>
                ]
            },
            {
                title: "What you'll learn",
                paragraphs: [
                    <ul className="learn_list">
                        <li>What makes Cloud Computing is so appealing today</li>
                        <li>How to navigate the Google Cloud Platform using the console</li>
                        <li>How resources in the Google Cloud Platform are organized</li>
                        <li>How to enable and work with APIs available in GCP</li>
                        <li>How permissions work in the Google Cloud Platform </li>
                    </ul>
                ]
            },
            {
                title: "What you'll gain in this series",
                paragraphs: [
                    <ul className="learn_list">
                        <li>Up to 60 days of free access to the Qwiklabs learning platform</li>
                        <li>Information on preparing for the Associate Cloud Engineer Certification</li>
                        <li>Hands-on experience with the Google Cloud Platform</li>
                        <li>Up to two skill badges from the Qwiklabs platform</li>
                    </ul>
                ]
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },

    {
        name: "Getting Started with Google Cloud",
        type: ["Workshop Series"],
        series: true,
        date: 'November 21st, 2020',
        time: "2:00pm - 3:30pm Eastern",
        forceShowRSVP: null,
        rsvpLink: "/rsvp/get-started-with-gcp",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: '/rsvp/get-started-with-gcp',
            forceShow: null,
        },
        image: csj1,
        url: "get-started-with-gcp",
        showVideo: false,
        videoId: 'R7d_MrAObG8',
        videoImageUrl: 'https://img.youtube.com/vi/R7d_MrAObG8/maxresdefault.jpg',
        summary: <p>
            Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the fundamental
            concepts and APIs available in the Google Cloud Platform. We'll introduce the Qwiklabs platform and the
            Google Cloud Platform Console, then dive into creating virtual machines with Compute Engine, setting up
            Kubernetes clusters with the Google Kubernetes Engine, and then explore networking and HTTP load
            balancing.
            <br /><br />
            After completing this event, members will have earned a badge to show off on their LinkedIn and social
            media profiles. Members can optionally complete a challenge lab to earn an additional badge.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join the FAU and UCF Google Developer Student Clubs and follow along as we explore some of the fundamental
                        concepts and APIs available in the Google Cloud Platform. We'll introduce the Qwiklabs platform and the
                        Google Cloud Platform Console, then dive into creating virtual machines with Compute Engine, setting up
                        Kubernetes clusters with the Google Kubernetes Engine, and then explore networking and HTTP load
                        balancing.</p>,
                    <p>
                        After completing this event, members will have earned a badge to show off on their LinkedIn and social
                        media profiles. Members can optionally complete a challenge lab to earn an additional badge.
                    </p>,
                ]
            },
            {
                title: "Prerequisites",
                paragraphs: [
                    <div>
                        <span>No prior Google Cloud experience required.</span>
                    </div>,
                    <div><br />
                        <span>For this event, you'll need a working computer with a modern browser (such as <a rel="noreferrer" target="_blank" className="blue_link" href="https://www.google.com/chrome/">Google Chrome</a>) and a stable internet connection.
                        </span><br /><br />
                    </div>
                ]
            },
            {
                title: "What you'll learn",
                paragraphs: [
                    <ul className="learn_list">
                        <li>What makes Cloud Computing is so appealing today</li>
                        <li>How to navigate the Google Cloud Platform using the console</li>
                        <li>How to create and manage cloud resources using the command line</li>
                        <li>How to create virtual machines in Compute Engine</li>
                        <li>How to set up and configure a Kubernetes cluster</li>
                        <li>How network and HTTP load balancing works</li>
                    </ul>
                ]
            },
            {
                title: "What you'll gain",
                paragraphs: [
                    <ul className="learn_list">
                        <li>Up to 60 days of free access to the Qwiklabs learning platform</li>
                        <li>Information on preparing for the Associate Cloud Engineer Certification</li>
                        <li>Hands-on experience with the Google Cloud Platform</li>
                        <li>Up to two skill badges from the Qwiklabs platform</li>
                    </ul>
                ]
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        sep: '2019-2020 Events',
        id: "2019-20-events"
    },
    {
        name: "Let's Build a Mobile App",
        type: ["Workshop", "Fun with Flutter - Part 1"],
        date: 'August 28th, 2020',
        time: "2:00pm - 3:30pm Eastern",
        forceShowRSVP: null,
        rsvpLink: "/rsvp/build-a-mobile-app",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: '/rsvp/build-a-mobile-app',
            forceShow: null,
        },
        image: flutter,
        url: "build-a-mobile-app",
        showVideo: true,
        videoId: 'RmezsavLhCA',
        videoImageUrl: 'https://img.youtube.com/vi/RmezsavLhCA/maxresdefault.jpg',
        summary: <p>
            Join us as our tech lead, Jonathan Yataco, introduces the significance of Flutter and follow along to
            create your own mobile weather app to demonstrate Flutter's capabilities.
            <br /><br />
            Jonathan Yataco is a Master's of Science student in Computer Science at Florida Atlantic University.
            He's currently an iOS software development intern at Cocobolo Group and Unify. His main interests lie in
            native iOS app development and lower-level programming for digital signal processing. As a tech lead for
            the FAU Google Developer Student Club, he engages with the student body in introducing and teaching various
            Google technologies.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us as our tech lead, Jonathan Yataco, introduces the significance of Flutter and follow along to
                        create your own mobile weather app to demonstrate Flutter's capabilities.</p>,
                    <p>
                        Jonathan Yataco is a Master's of Science student in Computer Science at Florida Atlantic University.
                        He's currently an iOS software development intern at Cocobolo Group and Unify. His main interests lie in
                        native iOS app development and lower-level programming for digital signal processing. As a tech lead for
                        the FAU Google Developer Student Club, he engages with the student body in introducing and teaching various
                        Google technologies.
                    </p>,
                ]
            },
            {
                predefined: 'BaMAPreReq',
            },
            {
                title: "What you'll learn",
                paragraphs: [
                    <ul className="learn_list">
                        <li>What makes Flutter such a powerful tool for programming mobile apps</li>
                        <li>How to create a mobile weather app using Flutter</li>
                        <li>How to interact with APIs in Flutter</li>
                    </ul>
                ]
            },
            {
                title: "Resources",
                paragraphs: [
                    <ul className="blue-links">
                        <li><Link to="/help/flutter-install">Flutter Installation Guide by Jonathan</Link> - a guide prepared by
                            Jonathan for installing Flutter and Android Studio</li>
                        <li><a rel="noreferrer" target="_blank" href="https://flutter.dev/docs/get-started">Flutter - Get Started</a> -
                            documentation by
                            Flutter for getting started</li>
                        <li><a rel="noreferrer" target="_blank" href="https://code.visualstudio.com/Download">Visual Studio Code</a> - the
                            code editor that
                            Jonathan will be using</li>
                        <li><a rel="noreferrer" target="_blank" href="https://go.dscfau.com/discord">FAU GDSC Discord Server</a> - ask us any
                            questions
                            you have in the <em>#lets-build-a-mobile-app</em> channel</li>
                    </ul>
                ]
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "Introduction to Quantum Computing",
        type: ["Workshop"],
        date: 'July 25th, 2020',
        time: <span>{"10:30am - 12pm EDT)"}<br />{"(8pm to 9:30pm IST"}</span>,
        forceShowRSVP: null,
        rsvpLink: "https://go.dscfau.com/rsvp/quantum",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: 'https://go.dscfau.com/rsvp/quantum',
            forceShow: null,
        },
        image: dscDefault,
        url: "quantum",
        showVideo: true,
        videoId: 'XU23mQ4gV3Y',
        videoImageUrl: 'https://img.youtube.com/vi/XU23mQ4gV3Y/maxresdefault.jpg',
        summary: <p>
            Join us on July 25th as Syed Farhan introduces Quantum Computing using the Cirq framework and Python.
            During this
            session, you'll discover some of the practical applications of Quantum Computing in the modern world.
            Follow along with
            Syed and learn how to use the IBM Q Circuit Composer to create your first Quantum Circuit and entangle 2
            qubits!
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us on July 25th as Syed Farhan introduces Quantum Computing using the Cirq framework and Python.
                        During this
                        session, you'll discover some of the practical applications of Quantum Computing in the modern world.
                        Follow along with
                        Syed and learn how to use the IBM Q Circuit Composer to create your first Quantum Circuit and entangle 2
                        qubits!</p>,
                    <p>
                        Syed Farhan is the Founder of QPower, an educational organization for Quantum Computing, and Tesseract
                        Coding, a
                        community for tech enthusiasts. He has several years of experience as a Software Developer at Citriot
                        and loves to grow
                        with tech communities. He is also a Major in Electronics at RVCE, India and has great interests in
                        Multi-Core
                        Architecture and Neuromorphic Computing.
                    </p>,
                ]
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "Flutter Me Some Dart",
        type: [<span>with Hendrix Tavarez<br /><br /></span>, "Workshop", "Building Apps with Flutter"],
        date: 'June 25th, 2020',
        time: "4pm to 6pm Eastern",
        forceShowRSVP: null,
        rsvpLink: "/rsvp/flutter-1",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: '/rsvp/flutter-1',
            forceShow: null,
        },
        image: flutter,
        url: "flutter-me-some-dart",
        showVideo: true,
        videoId: 'A_M66JhYrAk',
        videoImageUrl: 'https://i.ytimg.com/vi/A_M66JhYrAk/sddefault.jpg',
        summary: <p>
            Join us as Hendrix Tavarez introduces Dart and Flutter. During this session follow along with Hendrix
            and learn to build
            your first Flutter App!
            <br /><br />
            <a target="_blank" rel="noreferrer" href="https://github.com/lockerfish">Hendrix Tavarez</a> is the founder of <a
                target="_blank" rel="noreferrer" href="https://lockerfish.com/">Lockerfish Labs</a>, a
            software consultancy agency. He has many years of
            experience
            developing software, providing training and building communities. Hendrix helps the tech community by
            organizing GDG
            Central Florida, Flutter Devs, GDG Bronx and DevFest Florida. He is also the GDG North America South
            Region Mentor.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us as Hendrix Tavarez introduces Dart and Flutter. During this session follow along with Hendrix
                        and learn to build
                        your first Flutter App!</p>,
                    <p>
                        <a rel="noreferrer" target="_blank" href="https://github.com/lockerfish">Hendrix Tavarez</a> is the founder of <a
                            rel="noreferrer" target="_blank" href="https://lockerfish.com/">Lockerfish Labs</a>, a
                        software consultancy agency. He has many years of
                        experience developing software, providing training and building communities. Hendrix helps the tech
                        community by organizing GDG Central Florida, Flutter Devs, GDG Bronx and DevFest Florida. He is also the
                        GDG North America South Region Mentor.
                    </p>
                ]
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "Introduction to Machine Learning: Language Processing",
        type: ["Study Jam 4", "Machine Learning on GCP - Part 1"],
        date: 'June 20th, 2020',
        time: "2pm to 4pm Eastern",
        forceShowRSVP: null,
        rsvpLink: "/rsvp/ml-1",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: '/rsvp/ml-1',
            forceShow: null,
        },
        image: csj4,
        url: "csj4",
        showVideo: true,
        videoId: 'V5iF0m6Uwxc',
        videoImageUrl: 'https://img.youtube.com/vi/V5iF0m6Uwxc/maxresdefault.jpg',
        summary: <p>
            Join us as Shing Tsoi introduces ML during part one of our new Cloud Study Jam series called Machine
            Learning on GCP.
            <br /><br />
            Learn the foundational concepts of machine learning and understand how the Google Cloud Platform makes
            your AI and Machine Learning workflow easier, quicker, and more reliable. Shing will discuss multiple
            language and
            speech APIs available on the Google Cloud Platform to help you analyze and translate language, speech,
            and text using the Natural Language and Cloud Speech APIs.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Join us as Shing Tsoi introduces ML during part one of our new Cloud Study Jam series called Machine
                        Learning on GCP.</p>,
                    <p>
                        Learn the foundational concepts of machine learning and understand how the Google Cloud Platform makes
                        your AI and Machine Learning workflow easier, quicker, and more reliable. Shing will discuss multiple
                        language and speech APIs available on the Google Cloud Platform to help you analyze and translate language, speech,
                        and text using the Natural Language and Cloud Speech APIs.
                    </p>,
                    <p>And as a part of our Cloud Study Jams, participants will receive a code to access the Intro to ML: Language Processing quest on Qwiklabs to give you hands-on experience using Machine Learning on GCP.</p>
                ]
            },
            {
                predefined: "STUDY_JAM",
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "Security and Identity Fundamentals",
        type: ["Study Jam"],
        date: 'May 15th, 2020',
        time: null,
        forceShowRSVP: null,
        rsvpLink: "/register/studyjam3",
        rsvp: {
            isOpen: false,
            type: 'external',
            url: '/register/studyjam3',
            forceShow: null,
        },
        image: csj3,
        url: "csj3",
        showVideo: true,
        videoId: 'tpj3rK4KmRA',
        videoImageUrl: 'https://img.youtube.com/vi/tpj3rK4KmRA/maxresdefault.jpg',
        summary: <p>
            Security is an underlying feature of the Google Cloud Platform. Understanding how to secure applications
            and manage users is an essential part of working with the Cloud. In this Study Jam, students will get
            hands-on practice with GCP's Identity and Access Management service (IAM), which is the go-to for
            managing user and virtual machine accounts. Students will learn about various tools to help them secure
            their applications, and will explore using Virtual Private Clouds to further protect and connect
            resources.
            <br /><br />
            For students new to the Google Cloud Platform, we will also be introducing basic GCP concepts, including
            using Compute Engine, Kubernetes Engine, and Load Balancers, and will get the chance to test their
            skills at the end with a challenge lab.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Security is an underlying feature of the Google Cloud Platform. Understanding how to secure applications
                        and manage users is an essential part of working with the Cloud. In this Study Jam, students will get
                        hands-on practice with GCP's Identity and Access Management service (IAM), which is the go-to for
                        managing user and virtual machine accounts. Students will learn about various tools to help them secure
                        their applications, and will explore using Virtual Private Clouds to further protect and connect
                        resources.</p>,
                    <p>
                        For students new to the Google Cloud Platform, we will also be introducing basic GCP concepts, including
                        using Compute Engine, Kubernetes Engine, and Load Balancers, and will get the chance to test their
                        skills at the end with a challenge lab.
                    </p>
                ]
            },
            {
                predefined: "STUDY_JAM",
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "Google Developer Essentials",
        type: ["Study Jam 2"],
        date: 'April 7th, 2020',
        time: null,
        forceShowRSVP: null,
        rsvpLink: null,
        rsvp: {
            isOpen: false,
            type: null,
            forceShow: false,
        },
        image: csj2,
        url: "csj2",
        showVideo: false,
        videoId: '',
        videoImageUrl: '',
        summary: <p>
            Google Developer Essentials allows students to learn all about using GCP technologies to build secure,
            scalable, and intelligent cloud native applications. Students learn how to develop and scale
            applications without the need to manage infastructure, run data analytics and gain insights from data,
            and how to develop and work with pre-trained ML APIs to leverage machine learning without needing to be
            a Machine Learning expert.
            <br /><br />Students will work with BigQuery, AutoML Vision, and the Natural Language API
            to analyze data trends, classify images, and break down text. They will get hands-on practice using
            Cloud Functions, the Places API, and Dialog Flow to build a fully functional Google Assistant App.
            Students will also work with App Engine to deploy applications and learn how to use Cloud Monitoring to
            autoscale instance groups.
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Google Developer Essentials allows students to learn all about using GCP technologies to build secure,
                        scalable, and intelligent cloud native applications. Students learn how to develop and scale
                        applications without the need to manage infastructure, run data analytics and gain insights from data,
                        and how to develop and work with pre-trained ML APIs to leverage machine learning without needing to be
                        a Machine Learning expert.</p>
                ]
            },
            {
                predefined: "STUDY_JAM",
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
    {
        name: "GCP Essentials",
        type: ["Study Jam 1"],
        date: 'February 8th, 2020',
        time: null,
        rsvp: {
            isOpen: false,
            type: null,
            forceShow: false,
        },
        image: csj1,
        url: "csj1",
        showVideo: false,
        videoId: '',
        videoImageUrl: '',
        summary: <p>
            Study Jams are group learning programs designed to give students practical experience in Google Cloud
            technologies. Students come together to build their skills through interactive lessons and hands-on
            labs.
            <br /><br />
            Google Cloud Platform Essentials is all about learning the fundamental skills necesary when using the
            GCP, from creating Compute Engine instances and Kubernetes clusters to creating a real
            web server with load balancing.
            <br /><br />
            Upon completing a Study Jam quest, attendees earn a cool badge to show off their skills, and of course,
            it can be added to their LinkedIn profiles. Students also gain full access to Qwiklabs for up to two
            months, allowing them to explore the GCP and other Cloud technologies further until the next meetup!
        </p>,
        sections: [
            {
                title: "Event Information",
                paragraphs: [
                    <p>Google Cloud Essentials is all about learning the fundamental skills necesary when using the GCP. Participants got hands-on practice with the Google Cloud Platform by creating virtual machines, working with Kubernetes clusters, and configuring load balancers. Attendees then tested their skills at the end by completing a challenge lab.</p>
                ]
            },
            {
                predefined: "STUDY_JAM",
            },
            {
                predefined: "NEED_HELP",
            },
        ]
    },
]

export default events;