import React from 'react';
import { connect } from 'react-redux';
import '../../styles/Blogs.css'
import { Avatar } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';


function Blog(props) {
    const getRandomGColor = () => {
        var colors = ['#4285f4', '#ea4335', '#fbbc04', '#34A853']
        return colors[Math.floor(Math.random() * colors.length)]
    }
    const now = new Date();
    const toDateFormat = (d) => {
        var dOptions = {
            month: 'short',
        }
        if (d.getFullYear() === now.getFullYear()) {
            dOptions['day'] = 'numeric'
        } else {
            dOptions['year'] = 'numeric'
        }
        return d.toLocaleString('en-US', dOptions);
    }

    const goToBlog = (id => {
        let newUrl = `/blog/${id}`;
        props.history.push(newUrl)
    })

    const posts = props.blog.sort((a, b) => b.id - a.id).map((post => {
        return (
            <TableRow key={post.id} className="blog_row" onClick={() => { goToBlog(post.id) }} style={{ textDecoration: 'none', cursor: 'pointer' }} >
                <TableCell align="left">
                    <span className="b_date_tag"><span className="b_date">{toDateFormat(post.publishDate)}</span> <span className="b_div">/</span> <span className="b_tag">{post.mainTag}</span></span><br /><span className="b_name">{post.name}</span></TableCell>
                <TableCell align="right">
                    <Avatar sizes="105" width="105" height='105' style={post.author.image ? {} : { backgroundColor: (getRandomGColor()) }} className="b_image" src={post.author.image}>
                        {/* {post.author.initials} */}
                    </Avatar>
                </TableCell>
            </TableRow>

        )

    }))
    return (
        <div className="blogs">
            Welcome to the FAU Google Developer Student Club Blog! <br /><br />More content coming soon!
            <br />
            <br />
            <TableContainer className="tableContainer">
                <Table>
                    <TableBody>
                        {posts}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        blog: state.blog,
    }
}

export default connect(mapStateToProps, {})(Blog);
