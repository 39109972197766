import React from 'react'
import { connect } from 'react-redux';
import NotFound from './NotFound';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '../PageComponents/Button';
import ButtonGroup from '../PageComponents/ButtonGroup';


function BlogPost(props) {
    const post = props.blog.find(b => {
        return b.id.toString() === props.match.params.blogPost;
    })
    if (!post) {
        return <NotFound location={props.location} blog />
    }
    const convertDate = (date) => {
        const dateOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }

        return date.toLocaleString('en-US', dateOptions);
    }
    return (
        <React.Fragment>
            <div className="blog">
                <div className="goBack">
                    <ButtonGroup>
                        <Button useLink href="/blog" secondary>See all posts</Button>
                    </ButtonGroup>
                    <br /><br />
                </div>
                <div className="b_tag">{post.mainTag}</div>
                <br />
                <div className="b_name">{post.name}</div>

                <Grid style={{
                    marginTop: 20
                }} container spacing={3}>
                    <Grid style={{
                        paddingTop: 0,
                        paddingBottom: 0
                    }} className="gridItem" item xs={12} sm={12} md={3}>
                        <div className="b_author">{post.author.name}</div>
                        <div className="b_published">Published {convertDate(post.publishDate)}</div>
                        <Hidden mdUp>
                            <br />
                            <hr className="b_hr" />
                        </Hidden>
                    </Grid>
                    <Grid style={{
                        paddingTop: 0,
                        paddingBottom: 0
                    }} className="gridItem" item sm={12} md={9}>
                        <div className="b_content">
                            {post.body.map((paragraph, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {paragraph}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </Grid>
                </Grid>

            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        blog: state.blog
    }
}

export default connect(mapStateToProps, {})(BlogPost);
