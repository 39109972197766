import React from 'react'
import { Link } from 'react-router-dom'

function EventButtons(props) {
    var styles = {};
    if (props.event) {
        styles = {
            marginLeft: '25%',
            marginRight: '25%',
        }
    }
    if (!props.rsvp && !props.link) {
        return null;
    }

    let rsvp = null, details = null;
    if (props.rsvp) {
        rsvp = <a rel="noreferrer" target="_blank" href={props.rsvp} className="event-rsvp btn">RSVP</a>
    }
    if (props.link) {
        details = <Link className={`event-details btn${rsvp === null ? '' : ' btn-secondary'}`} to={props.link}>More details</Link>
    }

    return (
        <div className="event-buttons" style={styles}>
            {rsvp}
            {(rsvp && details) ? ' ' : null}{details}
        </div>
    )
}

export default EventButtons
