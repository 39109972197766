import React, { Component } from 'react';
import ButtonGroup from '../PageComponents/ButtonGroup';
import Button from '../PageComponents/Button';
import { Helmet } from 'react-helmet';

class InterestForm extends Component {
    state = {
    }
    render() {
        return (
            <div class="ns" style={{ textAlign: 'left' }}>
                <Helmet>
                    <title>Interest Form | FAU Google Developer Student Club</title>
                    <meta name="description" value="Let us know what topics you're interested! Bridging the gap between theory and practice." />
                    <link rel="canonical" href="https://gdscfau.com/interest" />
                </Helmet>

                <span className="title page-title">Interested in our club?</span>
                <br />
                <p>
                    Google Developer Student Clubs (GDSCs) are community groups supported by Google Developers for university students interested in Google developer technologies, although our events aim to cover any developer skill, including basic programming to machine learning.
                </p>
                <br />
                Students from all undergraduate or graduate programs with an interest in growing as a developer are welcome. By joining a GDSC, students grow their knowledge in a peer-to-peer learning environment and build solutions for local business and their community.
                <br />
                <p>If interested, fill out the form linked below!</p>
                <br />
                <ButtonGroup>
                    <Button newTab href="https://go.dscfau.com/interest-fau">GDSC FAU Interest Form</Button>
                </ButtonGroup>
                <br /><br /><br />
                <small>If something isn't working properly, please fill out <a target="_blank" rel="noreferrer" href="https://docs.google.com/forms/u/1/d/e/1FAIpQLScSQM5WadlSOIC-cUvurAuQB-VG8fcSogZ3loj0kGn0YakakA/viewform?usp=pp_url&entry.1753636239=https://gdscfau.com/interest">this website issues form</a>. <br />Be sure to include your contact email.</small>
            </div >
        );
    }
}

export default InterestForm;
