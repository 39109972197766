
const redirects = `
# Redirects
/join/flutter-me-some-dart              https://forms.gle/19AXia6c8QEe9L1a9
/studyjam1                              /events/csj1
/csj1                                   /events/csj1
/csj2/survey                            https://forms.gle/gaJbGGNkDBqgATUXA
# /register/studyjam3                   /events/csj3
/go/studyjam3                           https://google.qwiklabs.com/quests/40?event=Your&utm_source=qwiklabs&utm_medium=lp&utm_campaign=studyjam
/go/gcp-essentials                      https://google.qwiklabs.com/quests/23?event=Your&utm_source=qwiklabs&utm_medium=lp&utm_campaign=studyjam
/go/canvas                              https://canvas.fau.edu/enroll/3CJ98E
/solution                               /events/#solution-challenge
/survey/studyjam3                       https://forms.gle/oK1kxs4Kdyeo2Tir5
# /go/csj3-webex                          https://fau.webex.com/fau/onstage/g.php?MTID=e20fdb8e329d2f7ab48fa18ffa0134405
/go/csj3-webex                          /events/csj3
/go/contact                             https://bit.ly/DSCFAU
/go/discord                             https://discord.gg/39qJ3sE
/discord                                https://discord.gg/39qJ3sE
/rsvp/ml-1                              https://www.eventbrite.com/e/110051609326
/rsvp/csj4                              https://www.eventbrite.com/e/110051609326
/rsvp/flutter-me-some-dart              https://www.eventbrite.com/e/109950155876
/rsvp/flutter-1                         https://www.eventbrite.com/e/109950155876
/join/flutter-me-some-dart              https://forms.gle/19AXia6c8QEe9L1a9
/join/flutter-1                         https://forms.gle/19AXia6c8QEe9L1a9
/go/owlcentral                          https://fau.campuslabs.com/engage/organization/gdsc
/go/csj4-webex                          https://fau.webex.com/fau/onstage/g.php?MTID=e79e557fe1bf2df7d9180fa2d6a889889
/events/flutter1                        /events/flutter-me-some-dart
/ml-1                                   /events/csj4
/flutter-1                              /events/flutter-me-some-dart
/go/studyjam4                           https://google.qwiklabs.com/quests/82?event=Your&utm_source=qwiklabs&utm_medium=lp&utm_campaign=studyjam
/flutter-hendrix                        /events/flutter-me-some-dart

# Let's Build a Mobile App
/events/mobile-app                      /events/build-a-mobile-app
/mobile-app                             /events/build-a-mobile-app
/rsvp/mobile-app                        https://dsc.community.dev/e/mgbzt2/
/rsvp/build-a-mobile-app                https://dsc.community.dev/e/mgbzt2/
/events/lets-build-a-mobile-app         /events/build-a-mobile-app
/help/install-flutter                   /help/flutter-install
/mobile-app/network-call                https://gist.github.com/jonyataco/98a2df994907431c6d0d61a7422f2f73
/mobile-app/github                      https://github.com/DSC-FAU/Fun-With-Flutter-Event-App
/mobile-app/starter                     https://github.com/DSC-FAU/Fun-With-Flutter-Event-Starter
/survey/mobile-app                      https://forms.gle/BPbH1oR7Euxii94y5

# Get Started with GCP
/googlecloud                            /events/get-started-with-gcp/
/events/googlecloud                     /events/get-started-with-gcp
/events/getting-started-with-google-cloud /events/get-started-with-gcp
/events/getting-started-with-gcp        /events/get-started-with-gcp
/events/gcp                             /events/get-started-with-gcp
/events/google-cloud                    /events/get-started-with-gcp
/events/start-with-google-cloud         /events/get-started-with-gcp
/events/start-with-googlecloud          /events/get-started-with-gcp
/events/start-with-gcp                  /events/get-started-with-gcp
/events/csj5                            /events/get-started-with-gcp
/events/studyjam5                       /events/get-started-with-gcp
/events/gcp-essentials                  /events/get-started-with-gcp
/events/google-cloud-essentials         /events/get-started-with-gcp
/events/googlecloud-essentials          /events/get-started-with-gcp
/events/googlecloudessentials           /events/get-started-with-gcp

# Intro to Compute Engine
/events/compute                         /events/intro-to-compute-engine
/events/computeengine                   /events/intro-to-compute-engine
/events/computengine                    /events/intro-to-compute-engine
/events/compute-engine                  /events/intro-to-compute-engine
/events/intro-to-compute                /events/intro-to-compute-engine

# Intro to Cloud Shell
/cloudshell                             /events/intro-to-cloud-shell
/cloud-shell                            /events/intro-to-cloud-shell
/events/cloudshell                      /events/intro-to-cloud-shell
/events/cloud-shell                     /events/intro-to-cloud-shell
/events/intro-to-cloudshell             /events/intro-to-cloud-shell

# Info Event
/info                                   /events/info
/infosession                            /events/info
/info-session                           /events/info
/events/infosession                     /events/info
/events/info-session                    /events/info

# All About Git
/git                                    /events/all-about-git
/events/git                             /events/all-about-git

/rsvp/get-started-with-gcp              https://dsc.community.dev/events/details/developer-student-clubs-florida-atlantic-university-presents-getting-started-with-google-cloud/#/
/rsvp/get-started-with-google-cloud     /rsvp/get-started-with-gcp
/rsvp/get-started-with-googlecloud      /rsvp/get-started-with-gcp
/rsvp/googlecloud                       /rsvp/get-started-with-gcp
/rsvp/google-cloud                      /rsvp/get-started-with-gcp
/rsvp/gcp                               /rsvp/get-started-with-gcp
/rsvp/csj5                              /rsvp/get-started-with-gcp
/rsvp/studyjam5                         /rsvp/get-started-with-gcp
/rsvp/gcp-essentials                    /rsvp/get-started-with-gcp
/rsvp/google-cloud-essentials           /rsvp/get-started-with-gcp
/rsvp/googlecloud-essentials            /rsvp/get-started-with-gcp
/rsvp/googlecloudessentials             /rsvp/get-started-with-gcp

# /e pages
/e/mobile-app                           /events/build-a-mobile-app
/e/googlecloud                          /events/get-started-with-gcp
/e/googlecloud2                         /events/intro-to-compute-engine
/e/compute                              /events/get-started-with-gcp
/e/cloudshell                           /events/intro-to-cloud-shell
/e/cloud-shell                          /events/intro-to-cloud-shell
/e/kubernete                            /events/intro-to-kubernetes
/e/kubernetes                           /events/intro-to-kubernetes
/e/k8s                                  /events/intro-to-kubernetes
/e/loadbalancing                        /events/load-balancing
/e/loadbalancer                         /events/load-balancing
/e/load-balancing                       /events/load-balancing
/e/load-balancer                        /events/load-balancing
/e/info                                 /events/info
/e/google-cloud                         /e/googlecloud
/e/gcp                                  /e/googlecloud
/e/git                                  /events/all-about-git
/e/pong                                 /events/pong
/e/pong/rsvp                            /events/pong/rsvp
/events/pong/rsvp/in-person             https://forms.gle/m2DuPdP14fEZQpVb9              
/events/pong/rsvp/physical              https://forms.gle/m2DuPdP14fEZQpVb9              
/e/pong/rsvp/in-person                  https://forms.gle/m2DuPdP14fEZQpVb9
/e/pong/rsvp/physical                   https://forms.gle/m2DuPdP14fEZQpVb9
/e/regex                                /events/regex
/e/regex/rsvp                           /events/regex/rsvp

/e/python                               /events/python
/e/python/rsvp                          /events/python/rsvp
/e/py                                   /events/python
/e/py/rsvp                              /events/python/rsvp
/e/python-1                             /events/python1
/e/python-1/rsvp                        /events/python1/rsvp
/events/python-1                        /events/python1
/events/py1                             /events/python1
/e/py1                                  /events/python1
/e/py1/rsvp                             /events/python1/rsvp
/e/python-2                             /events/python2
/e/python-2/rsvp                        /events/python2/rsvp
/events/python-2                        /events/python2
/events/py2                             /events/python2
/e/py2                                  /events/python2
/e/py2/rsvp                             /events/python2/rsvp

/e/feb21-general-body-meeting           /events/feb21-general-body-meeting
/events/gbm                             /events/feb21-general-body-meeting
/events/feb-gbm                         /events/feb21-general-body-meeting
/events/gbm-feb                         /events/feb21-general-body-meeting
/e/gbm                                  /events/feb21-general-body-meeting
/e/feb-gbm                              /events/feb21-general-body-meeting
/events/feb-gmb                         /events/feb21-general-body-meeting
/events/gmb-feb                         /events/feb21-general-body-meeting
/e/gmb                                  /events/feb21-general-body-meeting
/e/feb-gmb                              /events/feb21-general-body-meeting
/e/gmb-feb                              /events/feb21-general-body-meeting
/e/gmb                                  /events/feb21-general-body-meeting
/events/gmb                             /events/feb21-general-body-meeting

/e/functions                            /events/functions

/events/csj5                            /events/get-started-with-gcp
/events/studyjam5                       /events/get-started-with-gcp
/events/gcp-essentials                  /events/get-started-with-gcp

/hackathon                              /events/hackapalooza
/hackapalooza                           /events/hackapalooza

# keep this below the above section
/e/:event                               /events/:event
/e                                      /events

# archive pages
# /archive/2019-20/events                 /events 200!
/archive/2019-20/events                 /events/#flutter2
/archive/2019-20/events/*               /events/:event 200!
/archive/2019-20/index.html             / 200!
/archive/2019-20                        / 200!

# in development
/resources/fb-events.js                 /events 200!
/events/fb_new.html                     /events 200!
/events/new.html                        /events 200!
/resources/styles/pages/events_new.css  /events 200!
/events/event/index.html                /events 200!
/resources/events.js                    /events 200!


/bevy                                   https://gdsc.community.dev/florida-atlantic-university/
/instagram                              https://www.instagram.com/gdscfau/
/insta                                  https://www.instagram.com/gdscfau/
/youtube                                https://www.youtube.com/channel/UCIQUqVhbQrY4nejlNbPLBiA
/yt                                     https://www.youtube.com/channel/UCIQUqVhbQrY4nejlNbPLBiA
/owlcentral                             https://fau.campuslabs.com/engage/organization/gdsc
/oc                                     https://fau.campuslabs.com/engage/organization/gdsc
`

var asArray = redirects.split('\n').filter(redirect => {
    if (redirect.startsWith('#') || (redirect.replace(/ /g, "") === "")) return false;
    return true;
})

var finalRedirects = asArray.map(redirect => {
    var redirectWithoutSpaces = redirect.split(/\s+/).join(' ');
    var parts = redirectWithoutSpaces.split(' ');
    // console.log(parts);
    return { from: parts[0], to: parts[1] }
})

export default finalRedirects

