import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import BlogPost from '../Pages/BlogPost';

function BlogAddTitle(props) {
    const { params } = props.match;
    let newPage = '/blog/' + params.blogPost;

    var blog = props.blog.find(b => b.id.toString() === params.blogPost)
    if (!blog) {
        return <BlogPost {...props} />
    }

    const postUrlName = blog.name.replace(/ /g, "-").toLowerCase().replace(/[^0-9A-Z-]+/gi, "");

    var currentName = params.name;

    if (currentName !== postUrlName) {
        newPage += '/' + postUrlName;
        return <Redirect to={newPage} />
    } else {
        return <BlogPost {...props} />
    }
}

const mapStateToProps = (state) => {
    return {
        'blog': state.blog,
    }
}

export default connect(mapStateToProps, {})(BlogAddTitle);
