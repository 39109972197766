import aaltaher from '../images/officers/aaltaher.jpeg';
import laboalzahab from '../images/officers/laboalzahab.jpeg';
import dblazanovic from '../images/officers/dblazanovic.jpeg';
import jserrano from '../images/officers/jserrano.jpg';
import mpuig from '../images/officers/mpuig.jpg';
import bthaw from '../images/officers/bthaw.jpg';
import bsalid from '../images/officers/bsalid.jpeg';
import malsaidi from '../images/officers/malsaidi.jpg';
import zsalekshahrezaee from '../images/officers/zsalekshahrezaee.jpg';
import ralagoz from '../images/officers/ralagoz.jpeg';
import greardon from '../images/officers/greardon.jpg';
import jmcduffee from '../images/officers/jmcduffee.jpg';


// import aciccolella from '../images/officers/aciccolella.jpeg';
// import rhaire from '../images/officers/rhaire.jpeg';
// import abotia from '../images/officers/abotia.jpg';
// import jmacdonald from '../images/officers/jmacdonald.jpg';
// import jyataco from '../images/officers/jyataco.png';
// import mpicault from '../images/officers/mpicault.jpg';
// import rparsons from '../images/officers/rparsons.jpg';
// import coneill from '../images/officers/coneill.jpg';
// import aarab from '../images/officers/aarab.png';
// import aeagle from '../images/officers/aeagle.png';
// import kbalay from '../images/officers/kbalay.jpg';
// import sgoldin from '../images/officers/sgoldin.jpg';
// import cengman from '../images/officers/cengman.png';
// import ppauliuchenka from '../images/officers/ppauliuchenka.jpg';
// import hsanghvi from '../images/officers/hsanghvi.png';

// import defaultImage from '../images/officers/default.svg';

const about = {
    advisor: {
        name: "Hanqi Zhuang",
        link: "",
    },

    officers: [
        {
            "group": "Core Team",
        },
        {
            name: "Ali Altaher",
            position: "President",
            image: aaltaher,
            badgeLink: "https://www.linkedin.com/in/ali-altaher-b6473094",
            bio: "I am a PhD candidate at Florida Atlantic University with research oriented toward AI and Machine Learning, concentrated on dynamic physics. Also, I worked on developing Quantum Cryptography Keys as part of my first PhD program. I was the co- founder, vice president and the president of Data Science and Machine Learning Club, Graduate and Professional Students Association \\ College of Engineering and Computer Science ambassador, Engineering Fab Lab ambassador, Boca Raton Recreation Center advisory board member and Student Conduct Board member. I am honored to serve the FAU community as a leader for the GDSC since August 2022.",
        },
        {
            name: "Louay Abo Alzahab",
            position: "Vice President",
            image: laboalzahab,
            badgeLink: "https://www.linkedin.com/in/louayaz",
            bio: "I am a graduate student at Florida Atlantic University currently doing my masters degree in Artificial intelligence. I am interested in computer vision and robotics.",
        },
        {
            name: "Danica Blazanovic",
            position: "Treasurer",
            image: dblazanovic,
            badgeLink: "",
            bio: "I am a graduate student at Florida Atlantic University enrolled in Master of Science in Artificial Intelligence. Currently working on my thesis on Federated Learning in Health and Medicine and generally my interest is in medical imaging. I worked as a TA in Data Structures and Algorithm Analysis in Python and currently a TA for Intro to Programming in Python. I served as Treasurer for the Data Science and Machine Learning Club and currently working on my TensorFlow Developer certification. Although I have had many hobbies in the past, currently all of them are related to learning all things related to AI.",
        },
        {
            name: "John Serrano",
            position: "Secretary",
            image: jserrano,
            badgeLink: "https://www.linkedin.com/in/john-serrano-a2a8681aa",
            bio: "I'm a Senior CE major at FAU. I'm always trying to expand my knowledge of computers and all the things that can be done with them. Besides this I'm interested in gaming, swimming, or trying to teach myself something new.",
        },
        {
            "group": "Marketing & Community",
        },
        {
            name: "Marco Puig",
            position: "Marketing Director",
            image: mpuig,
            badgeLink: "https://www.linkedin.com/in/marco-puig/",
            bio: "My name is Marco Puig, I am 19, and I am an undergraduate who is majoring in Computer Science as well as a Marketing Director for GDSC here at FAU. I enjoy biking, programming, as well as game creation.",
        },
        /*{
            name: "Benjamin Thaw",
            position: "Community Platforms Director",
            image: bthaw,
            badgeLink: "https://www.linkedin.com/in/benjamin-thaw/",
            bio: "I am a senior undergraduate student at Florida Atlantic University currently studying Computer Science and Computer Engineering. I am interested in all things technology, including web and backend, Cloud Computing, and Firebase. I served as a Technical Lead for GDSC FAU since January 2020 and as the Vice President since October 2021. In August 2022, I began a gap year program studying abroad in Israel and am currently serving as the Community Platforms Director for GDSC.",
            focus: ['JavaScript', 'Full-Stack Web Development', 'Cloud Computing', 'Firebase'],
        },*/
        {
            name: "Basya Salid",
            position: "Social Media Officer",
            image: bsalid,
            badgeLink: "",
            bio: "I am a graduate student at Florida Atlantic University enrolled in Master of Science in Information Technology with a concentration in business analytics. I am currently working with the LMS team at FAU as well as at Instructure-Canvas. I enjoy working in SQL, Tableau, and XLMinor. Academic research topics that interest me include Wireless Sensor Networks, Predictive Analytics, and Social Media Analysis. My hobbies include weightlifting, watching tv and YouTube, shopping, and building furniture.",
        },
        {
            "group": "Technical Team",
        },
        {
            name: "Mostapha Al-Saidi",
            position: "Executive Tech Lead",
            image: malsaidi,
            badgeLink: "https://www.linkedin.com/in/mustafa-alsaidi/",
            bio: "I am a CS PhD candidate at FAU. I am interested in a wide range of topics including robotics, AI, and cloud computing platforms. I was the founder of the Data Science and Machine Learning club at FAU and currently serve as the president of the club. I am also involved in GDSC as the Executive Tech Lead.",
        },
        {
            name: "Zahra Salek Shahrezaee",
            position: "Tech Lead",
            image: zsalekshahrezaee,
            badgeLink: "http://www.linkedin.com/in/zahra-salekshahrezaee-75903b96",
            bio: "I am Zahra Salek Shahrezaee. I am a computer engineering PhD candidate at Florida Atlantic University. My research focuses on big data preprocessing and data analytics. Also,I have been working in Business intelligence are for a few years.",
        },
        {
            name: "Rahmi Alagoz",
            position: "Tech Lead",
            image: ralagoz,
            badgeLink: "https://www.linkedin.com/in/huseyin-rahmi-alagoz-a18378b9/",
            bio: "I'm an international student at FAU and am pursuing a Master of Science in Data Analytics. During my studies, I focused on the Machine Learning field, namely Predictive Analytics and classification tasks. I recently did an internship at Prevedere Inc., one of the US's leading Predictive Planning / Consulting companies. I played a part in building forecasting models for several Fortune 500 companies, such as Samsung and Walmart. I'm proficient in MySQL, RapidMiner, Python, STATA, and R.",
            focus: ["MySQL", "RapidMiner", "Python", "STATA", "R"],
        },
        {
            name: "Garrett Reardon",
            position: "Tech Lead",
            image: greardon,
            badgeLink: "https://www.linkedin.com/in/garrett-reardon-495325181",
            bio: "I’m a senior Computer Science major here at FAU, with AI and Data Science being my main subjects of study. I’ve conducted research on path planning algorithms and am beginning a project involving image segmentation of brain MRIs. Besides academics, I enjoy practicing guitar, playing video games, and taking long walks.",
        },
        {
            name: "Julia McDuffee",
            position: "Tech Lead",
            image: jmcduffee,
            badgeLink: "www.linkedin.com/in/julia-mcduffee-729a25227",
            bio: "I am a undergraduate student at Florida Atlantic University pursing a major in cellular neuroscience. I am working towards applying to medical school, and currently work on undergraduate research concerning neuroscience and microscopy. ",
        },
    ],
    formerOfficers: [
        /*{
            name: "Alex Ciccolella",
            position: "President",
            image: aciccolella,
            badgeLink: "https://www.linkedin.com/in/alessandro-ciccolella/",
            bio: "I'm a computer science master's student at FAU. Before moving to CS I got my bachelors in information systems and I currently work IT at Blusleep. I am currently very interested in Artificial intelligence and data science. My core skills are Python, SQL, Java, and C++."
        },*/
        /*{
            name: "Robert Haire",
            position: "Vice President",
            image: rhaire,
            badgeLink: "https://www.linkedin.com/in/robert-haire-26096a144/",
            bio: "A senior in the Computer Engineering program at Florida Atlantic University. Manager at Blue Martini Lounge in Fort Lauderdale. Passionate about the intersection of technology and human interaction with it. Currently sinking my teeth into data science."
        },*/
        /* {
            name: "Ahmad Arab",
            position: "President",
            image: aarab,
            badgeLink: "https://linkedin.com/in/ahmad-a-b645a3bb",
            bio: "I’m a senior CS major attending FAU. I always enjoy trying to learn new things. Hobbies of mine include socializing, playing soccer, and sketching. I am open and happy to talk to other members of the club and looking forward to having fun all together!",
        }, */
        /* {
            name: "Aden Eagle",
            position: "Secretary",
            image: aeagle,
            badgeLink: "https://www.linkedin.com/in/aden-eagle-6a7948181/",
            bio: "I am a senior undergraduate student at Florida Atlantic University currently studying Data Analytics at the Wilkes Honors College. Having earned the TensorFlow Developer Certificate, I am interested in the intersection of machine learning and neuroscience and how they can meld to better inform our understanding of neural circuitry.",
        }, */
        /*{
            name: "Palina Pauliuchenka",
            position: "Marketing Director",
            image: ppauliuchenka,
            badgeLink: "#",
            bio: "I am a Computer Science major and I just finished my Freshman year at Florida Atlantic University as an International student from Belarus. I am interested in Artificial Intelligence and Algorithms. My goal is to become a Software Engineer as this is something I am passionate about. During my free, I like doing side projects, socializing, playing video games, and annoying my dog.",
        },*/
        /*{
            name: "Andres Botia",
            position: "Marketing Lead",
            image: abotia,
            badgeLink: "https://www.linkedin.com/in/andres-botia-b8908116b",
            bio: "I'm a junior CS major at FAU looking to start a career in software engineering. I've mainly worked on programs based on C++ and python. Besides academic work, I'm either working on a side project, at the gym, or practicing jiu jitsu.",
        }, */
        /*{
            name: "Ciara O'Neill",
            position: "Marketing Lead",
            image: coneill,
            badgeLink: "https://www.linkedin.com/in/ciara-o-neill-7b78b153",
            bio: "I am an undergraduate student at FAU pursuing a major in Computer Science and a minor in Linguistics. I'm interested in all sorts of different aspects of CS, including artificial intelligence, data science, web development, and computational linguistics. In my free time, I enjoy reading, writing, playing video games, and bothering my cat.",
        },*/
        /*{
            name: "Harshal Sanghvi",
            position: "Outreach Marketing Lead",
            image: hsanghvi,
            badgeLink: "#",
            bio: "I'm a Teaching Assistant and a Ph.D. Student (Computer Engineering) at Florida Atlantic University. My areas of interest are Data Science, Cloud Computing, Internet of Things (IoT), Web Development, Networking, Embedded System Design, Artificial Intelligence, Neural Networks. Besides my career in Computer Engineering, I also work in the areas of  Entrepreneurship Development and have been Visiting Faculty/Startup Expert/Guest Lecturer/ Resource Person at various Universities, Colleges, Educational Institutions, and Government Organizations.",
        },*/
        /* {
            name: "James MacDonald",
            position: "Tech Lead",
            image: jmacdonald,
            badgeLink: "https://www.linkedin.com/in/james-r-mac",
            bio: "I’m a junior computer science major at FAU with aspirations to be a software engineer. I’m currently applying for internships to gain more experience and knowledge to better myself after graduation. Right now I mainly work with Python, SQL and C++. I love all things in technology and am currently interested in Cloud Computing, backend development, app development and big data. Outside of school you’ll find me spending time with my friends and family or outside enjoying nature.",
        },*/
        /* {
            name: "Ross Parsons",
            position: "Tech Lead",
            image: rparsons,
            badgeLink: "https://www.linkedin.com/in/ross-parsons-9aaba713b/",
            bio: "I am a computer science major currently in my senior year at FAU. When I am not studying or working on side projects, I am scuba diving or at the gym. My main interests in software engineering are backend development, IoT, and app development. Most of my experience is with Python and C++.",
        },*/
        /*{
            name: "Seth Goldin",
            position: "Executive Tech Lead",
            image: sgoldin,
            badgeLink: "https://github.com/GoldinGuy",
            bio: "I'm an undergraduate student at the FAU Honors College currently studying Computer Science and Data Science with a focus on Neuroscience. I love reading about and discussing anything tech-related, and working on various programming projects such as apps or websites. I also enjoy running.",
        },*/
        /*{
            name: "Jonathan Yataco",
            position: "Tech Lead",
            image: jyataco,
            badgeLink: "#",
            bio: "I am a Masters student in Computer Science at FAU. I currently work as an iOS developer, and have interests in mobile development and back end development. My favorite languages are Swift and Python. In my free time I enjoy running and playing music.",
        },*/
        /* {
            name: "Christian Engman",
            position: "Tech Lead",
            image: cengman,
            badgeLink: "https://www.linkedin.com/in/christian-engman-0a7952181",
            bio: "I'm a junior undergraduate student at FAU studying Computer Science and Mathematics. My main areas of interest are in Theoretical Computer Science, such as Algorithms and Information Theory. I'm also heavily interested in cybersecurity, privacy issues, and the ways they relate to society at large. Though I have many years of programming experience in languages like Python and C++, I am looking to learn more about web development and related technologies.",
            focus: ["Python", "C and C++", "Computational Programming", "Algorithm Design", "Cryptography", "Cybersecurity"],
        }, */
        /*{
            name: "Klark Balay",
            position: "Tech Lead",
            image: kbalay,
            badgeLink: "#",
            bio: "I'm a junior undergraduate student at Florida Atlantic University, studying Computer Science. Projects I have worked on are typically in C++ or Python, and I am interested in Artificial Intelligence. I aspire to one day become a software engineer for a well-known company, and work my way up to an executive position. Outside of programming, I love going mountain biking, nature trips, and doing karaoke!",
        },*/
        /* {
            name: "Mohamed Picault",
            position: "Tech Lead",
            image: mpicault,
            badgeLink: "https://github.com/MohamedPicault",
            bio: "I’m an undergraduate student at FAU, currently a junior in the computer science program, and a project lead of an undergraduate research program. My areas of interest in software engineering are mobile development, gaming development, back and front-end development. I am always looking to add new programming languages to my arsenal. My current programming experience is with dart, html, and swift to name a few. Outside of programming I am either gaming or working on side projects.",
        },*/ /*{
            name: "Nicolas Serna",
            position: "Tech Lead",
            image: defaultImage,
            badgeLink: "#",
            bio: "",
            visible: false,
        },*/

    ],
}
export default about;
