import React from 'react';
import { connect } from 'react-redux';
import NotFound from './NotFound';
import '../../styles/Event.css';
import EventBadge from '../PageComponents/Events/EventBadge';
import { CircularProgress } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Button from '../PageComponents/Button';
import { Helmet } from 'react-helmet';

function EventRsvp(props) {
    const { match } = props;
    const { params } = match;
    const eventName = params.event;
    const event = props.events.find(ev => ev.url === eventName);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    if (event === undefined) {
        return (<NotFound location={props.location} event />)
    }

    if (!event.rsvp) {
        return (
            <EventWrapper>
                Sorry, event registration isn't available yet. Please try again later or contact a club officer.
            </EventWrapper>
        )
    }
    const { rsvp } = event;

    if (event.rsvp.isOpen === false) {
        return (
            <EventWrapper>
                Sorry, event registration has closed.
            </EventWrapper>
        )
    }




    function EventWrapper(wrapperProps) {
        return (
            <div style={{ textAlign: 'center' }} className="event_page rsvp">
                <Helmet>
                    <title>{`Registration - ${event.name} | FAU Google Developer Student Club`}</title>
                    <meta name="description" value="Bridging the gap between theory and practice through interactive, hands-on events focused on developer tools." />
                    <link rel="canonical" href={`https://gdscfau.com/events/${params.event}/rsvp`} />
                </Helmet>
                <section className="event_description">
                    <div className="event">
                        <EventBadge event={event} />
                        <h3>Event Registration</h3>
                        <h3 style={{ fontSize: '1.5rem' }}>{event.name}</h3>
                        <br />
                        {wrapperProps.children}
                        <br /><br /><br />
                        <Link component={Button} secondary to={`/events/${event.url}`}>See event page here</Link>
                        <br /><br /><br />
                        <small>Something broken or incorrect?<br />Please let a club officer know in the <a href="/go/discord">Discord server</a>.</small>
                    </div>
                </section>
            </div>
        )
    }


    // if ()



    if (event.rsvp && event.rsvp.type === 'external') {
        window.location.replace(event.rsvp.url);
        return (
            <EventWrapper>
                <h1 style={{ textAlign: 'center' }}>Redirecting...</h1>
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
                <p style={{ textAlign: 'center' }}><a href={event.rsvp.url}>Not working? Click here instead.</a></p>
            </EventWrapper>
        )
    }

    if (event.rsvp.type === 'gform') {
        const url = `https://docs.google.com/forms/d/e/${rsvp.formId}/viewform`;
        const embedUrl = `${url}?embedded=true`;
        return (
            <EventWrapper>
                <small>Registration form not loading? <br /><a href={url} target="_blank" rel="noreferrer">Click here to open in a new tab.</a></small>
                <br /><br />
                {
                    hasLoaded ? (null) : (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                    )
                }
                <div>
                    <iframe style={hasLoaded ? {} : { display: 'none' }} title="Event registration form" src={embedUrl}
                        onLoad={() => { setHasLoaded(true) }}
                        width="100%" height={rsvp.height ? rsvp.height : '1000px'} frameBorder="0" marginHeight="0" marginWidth="0">
                        Loading…
                    </iframe>
                </div>

            </EventWrapper>
        )
    }

    return (
        <EventWrapper>
            Sorry, event registration isn't available yet. Please try again later or contact a club officer.
        </EventWrapper>
    )

    // let rsvpContent;
    // switch(event.rsvp.type) {
    //     case 'gform': 
    //     rsvpContent = <React.Fragment>
    //         gform
    //     </React.Fragment>
    //         break;
    //     case 'external':
    //         rsvpContent = <
    //     default:
    //         rsvpContent = <React.Fragment>

    //         </React.Fragment>
    //         break;
    // }

    // return (
    //     <div>
    //         hi
    //         <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfEfmXCTwrN4CT5VR_IdaZgGHcmfr1SLO74hu4G1nMc8OX1XQ/viewform?embedded=true"
    //         width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    //     </div>
    // )
}

const mapStateToProps = (state) => {
    return {
        events: state.events
    }
}

export default connect(mapStateToProps, {})(EventRsvp);
