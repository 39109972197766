import { Collapse, Drawer, Icon, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
        margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
}));


function ListItemLink(props) {
    // return (<span>what </span>)
    const { to, name, icon, activeOnSubPath, className } = props;
    const location = useLocation();

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <NavLink to={to} ref={ref} {...itemProps} />),
        [to],
    );
    // const renderHashLink = React.useMemo(
    //     () => React.forwardRef((itemProps, ref) => <NavHashLink to={to} ref={ref} {...itemProps} />),
    //     [to],
    // );

    const showAsSelected = (location.pathname === to) ? true : (
        (activeOnSubPath === true) ? (location.pathname.startsWith(to) ? true : false) : false)

    // console.log(props);
    // console.log('hi')


    return (
        // <li style={marginLeft === true ? { paddingLeft: 10, paddingRight: 10 } : {}}>
        <ListItem
            onClick={() => { props.toggleMobileNav(false) }}
            className={className}
            selected={showAsSelected} style={{ borderRadius: 5 }} button component={renderLink}>
            {/* <ListItem selected={showAsSelected} style={{ borderRadius: 5 }} button component={hash ? renderHashLink : renderLink}> */}
            {icon ? <ListItemIcon><Icon><img class="mobile_nav_dropdown_icon" alt="" height="24px" width="24px" src={icon} /></Icon></ListItemIcon> : null}
            <ListItemText primary={name} />
        </ListItem>
        // </li>
    );
}


function MobileNavbar(props) {
    const { navbar } = props;
    const classes = useStyles();
    const [ddOpen, setDdOpen] = React.useState({});
    const location = useLocation();

    /**
     * 
     * @param {Event} e 
     */
    const handleDdClick = (e, itemName) => {
        e.preventDefault();
        setDdOpen({
            ...ddOpen,
            [itemName]: !(ddOpen[itemName])
        })
    }

    var mobileNavItems = navbar.length === 0 ? null : (
        navbar.map((navItem, index) => {
            if (navItem.dropdownItems === undefined) {
                return (
                    <ListItemLink
                        toggleMobileNav={props.toggleMobileNav}
                        key={index} icon={navItem.icon} to={navItem.to} name={navItem.name} />
                )
            } else {
                return (
                    <React.Fragment key={index}>
                        <ListItem button onClick={(e) => { handleDdClick(e, navItem.name) }}
                            selected={(location.pathname === navItem.to) ? true : (
                                (navItem.activeOnSubPath === true) ? (location.pathname.startsWith(navItem.to) ? true : false) : false)}
                        >
                            {/* <ListItemIcon></ListItemIcon> */}
                            <ListItemText
                                style={{ borderRadius: 5 }}
                                primary={navItem.name} />
                            {(ddOpen[navItem.name]) ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={ddOpen[navItem.name]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemLink toggleMobileNav={props.toggleMobileNav}
                                    className={classes.nested} icon={navItem.image} name={`Go to ${navItem.name}`} to={navItem.to}
                                />
                                {
                                    navItem.dropdownItems.map((item, index) => {
                                        if (item.section) {
                                            return (
                                                <Typography key={index}
                                                    className={classes.dividerFullWidth}
                                                    color="textSecondary"
                                                    display="block"
                                                    variant="caption"
                                                >
                                                    {item.name}
                                                </Typography>
                                            )
                                        } else {
                                            return (
                                                <ListItemLink
                                                    toggleMobileNav={props.toggleMobileNav}
                                                    key={index} className={classes.nested} icon={item.image} to={item.to} name={item.name} />


                                            )
                                        }
                                    })
                                }

                            </List>
                        </Collapse>
                    </React.Fragment>
                )
            }
        })
    )
    return (
        <React.Fragment>
            <Drawer anchor='right' open={props.open}
                onClose={() => { props.toggleMobileNav(false) }}>
                <div
                    // onClick={() => { props.toggleMobileNav(false) }}
                    onKeyDown={() => { props.toggleMobileNav(false) }}
                    className={clsx(classes.list)}>
                    <List>
                        {mobileNavItems}
                    </List>
                </div>
            </Drawer>
        </React.Fragment>
    )
}

export default MobileNavbar
