import React from 'react';
import { Link } from 'react-router-dom';

function Button(props) {
    const { useLink, href, secondary, children, spaceAfter, newTab } = props;
    var classList = ['btn'];
    if (secondary === true) {
        classList.push('btn-secondary');
    }
    let additionalProps = {};
    if (newTab) {
        additionalProps['target'] = '_blank'
        additionalProps['rel'] = 'noreferrer'
    }
    return (
        <React.Fragment>
            {
                (useLink === true) ? (
                    <Link {...additionalProps} className={classList.join(' ')} to={href}>{children}</Link>
                ) : (
                        <a {...additionalProps} href={href} className={classList.join(' ')}>{children}</a>
                    )
            }
            {spaceAfter === true ? " " : ""}


        </React.Fragment>
    )
}

export default Button;