import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EventBadge extends Component {

    state = {
        classList: 'event-badge no-br',
    }

    handleMouseDown = (e) => {
        this.setState({
            classList: 'event-badge no-br clicked'
        })
    }
    handleMouseUp = (e) => {
        this.setState({
            classList: 'event-badge no-br'
        })
    }

    handleEventBadgeAnchorClick = (e) => {
        e.preventDefault();
    }

    render() {
        const { events } = this.props;
        const { name, image, url } = this.props.event;
        if (events) {

            return (

                <div className="event-badge no-br">
                    <Link to={`/events/${url}`}>
                        <img className="normal" alt={`${name} badge`} src={image} />
                    </Link>
                </div>
            );
        } else {
            return (

                <div style={{ marginBottom: '10px' }} className={this.state.classList} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}>
                    <a href={`/events/${url}`} onClick={this.handleEventBadgeAnchorClick}>
                        <img className="normal" alt={`${name} badge`} src={image} />
                    </a>
                </div>
            );
        }
    }
}

export default EventBadge;
