import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Grid, SvgIcon } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import '../../styles/NewStyles.css'

function Footer(props) {
    return (
        <footer>
            <div className="footer-icons">
                <ul>
                    <li>
                        <a rel="noreferrer" href="https://www.youtube.com/channel/UCIQUqVhbQrY4nejlNbPLBiA" target="_blank">
                            <YouTubeIcon />
                        </a>
                    </li>
                    <li>
                        <a rel="noreferrer" href="https://www.instagram.com/gdscfau/" target="_blank">
                            <InstagramIcon />
                        </a>
                    </li>
                    <li>
                        <a rel="noreferrer" href="https://discord.gg/39qJ3sE" target="_blank">
                            <SvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36"><g id="图层_2" data-name="图层 2"><g id="Discord_Logos" data-name="Discord Logos"><g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo - Large - White"><path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" /></g></g></g></svg>
                            </SvgIcon>
                        </a>
                    </li>
                    <li>
                        <a rel="noreferrer" href="https://twitter.com/dscfau" target="_blank">
                            <TwitterIcon />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="footer-parent">
                <div className="new-footer ns">

                    <br />
                    <Grid className="footer-grid" container spacing={3}>
                        <Grid item md={6} sm={12} >
                            <h1 className="title footer-title">FAU Google Developer Student Club</h1>
                            <p>Bridging the gap between <strong>theory</strong> and <strong>practice</strong>.</p>
                            <p>Join us to participate in fun, engaging events covering a wide range of developer topics and tools!</p>
                            <p>Email us at <a href="mailto:dscfau@gmail.com">dscfau@gmail.com</a></p>
                            <p className="credits">Theme made by <a rel="noreferrer" target="_blank" href="https://github.com/Joy2469">Aditi Jain</a>, Built and maintained by <a rel="noreferrer" target="_blank" href="https://github.com/bthaw2018">Benjamin Thaw</a>.</p>

                        </Grid>
                        <Grid item md={3} >
                            <h2 className="subtitle link_header">Learn More</h2>
                            <ul className="link_list">
                                <li><a href="https://developers.google.com/community/dsc" rel="noreferrer" target="_blank">Google Developer Student Clubs</a></li>
                                <li><a href="https://fau.edu" rel="noreferrer" target="_blank">Florida Atlantic University</a></li>
                                <li><Link to="/about">Meet our Team</Link></li>
                            </ul>
                        </Grid>
                        <Grid item md={3} >
                            <h2 className="subtitle link_header">Join our Community</h2>
                            <ul className="link_list">
                                <li><a href="https://go.gdscfau.com/discord" rel="noreferrer" target="_blank">Discord Server</a></li>
                                <li><a href="https://go.gdscfau.com/owlcentral" rel="noreferrer" target="_blank">Owl Central</a></li>
                                <li><a href="https://go.gdscfau.com/bevy" rel="noreferrer" target="_blank">GDSC Community Platform</a></li>
                                <li><a href="https://www.youtube.com/channel/UCIQUqVhbQrY4nejlNbPLBiA" rel="noreferrer" target="_blank">YouTube Channel</a></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <p className="website-issues"><a href={`https://docs.google.com/forms/d/e/1FAIpQLScSQM5WadlSOIC-cUvurAuQB-VG8fcSogZ3loj0kGn0YakakA/viewform?usp=pp_url&entry.1753636239=${encodeURIComponent(window.location.protocol + "//" + window.location.host + props.location.pathname)}`} target="_blank" className="link no-format" rel="noreferrer">Please report website issues here</a></p>
                </div>
            </div>
            <div style={{ display: 'none' }} className="footer-main-div">
                <div className="footer-icons">
                    <ul>
                        <li>
                            <a rel="noreferrer" href="https://www.youtube.com/channel/UCIQUqVhbQrY4nejlNbPLBiA" target="_blank">
                                <YouTubeIcon />
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" href="https://www.instagram.com/gdscfau/" target="_blank">
                                <InstagramIcon />
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" href="https://discord.gg/39qJ3sE" target="_blank">
                                <SvgIcon>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 240"><path d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" /><path d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" /></svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36"><g id="图层_2" data-name="图层 2"><g id="Discord_Logos" data-name="Discord Logos"><g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo - Large - White"><path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" /></g></g></g></svg>
                                </SvgIcon>
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" href="https://twitter.com/dscfau" target="_blank">
                                <TwitterIcon />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default withRouter(Footer);