import React from 'react'
import { connect } from 'react-redux'
import NotFound from './NotFound';
import '../../styles/Event.css';
import EventBadge from '../PageComponents/Events/EventBadge';
import StudyJamPredefined from '../PageComponents/Events/StudyJamPredefined';
import NeedHelpPredefined from '../PageComponents/Events/NeedHelpPredefined';
import EventSection from '../PageComponents/Events/EventSection';
import EventButtons from '../PageComponents/Events/EventButtons';
import EventVideo from '../PageComponents/Events/EventVideo';
import BaMAPreReq from '../PageComponents/Events/BaMAPreReq';
import { Link } from 'react-router-dom';
import { Card, Box, Button, Hidden, CardContent, CardActions, Typography, Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'

const LinkBehavior = React.forwardRef((props, ref) => (
    <Link ref={ref} {...props} />
));

const useStyles = makeStyles({
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }, gridItem: {
        display: 'flex',
    }
});

function Event(props) {
    const { match } = props;
    const { params } = match;
    const [showAllEventsInSeries, setShowAllEventsInSeries] = React.useState(false);
    const classes = useStyles();
    const eventName = params.event;
    const event = props.events.find(ev => ev.url === eventName);
    if (event === undefined) {
        return (<NotFound location={props.location} event />)
    }

    const eventDateCorrectFormat = event.date
        .replace('st', '')
        .replace('nd', '')
        .replace('rd', '')
        .replace('th', '')
    const eventDateAsDate = new Date(eventDateCorrectFormat + " 11:59 PM EST");

    const showDate = () => {
        return (
            <span className="event-date">{event.date}</span>
        )
    }
    const showDateWithTime = () => {
        return (
            <span className="event-date">{event.date} ({event.time})</span>
        )
    }

    const getPredefinedSection = (section, index) => {
        switch (section.predefined) {
            case 'STUDY_JAM':
                return <StudyJamPredefined key={index} />;
            case 'NEED_HELP':
                return <NeedHelpPredefined key={index} />;
            case 'BaMAPreReq':
                return <BaMAPreReq key={index} />
            default:
                return null;
        }
    }

    var parent = (event.parent ? props.events.find(ev => ev.url === event.parent) : null);

    var subEventsContent = null;
    var subEvents = (event.series ? props.events.filter(ev => ev.parent === event.url) : null);
    if (event.series) {
        if (subEvents.length >= 1) {
            subEventsContent = subEvents.map((subEvent, index) => {
                var doesRsvpShow = subEvent.rsvp ? (
                    subEvent.rsvp.forceShow === false ? false : (
                        subEvent.rsvp.forceShow === true ?
                            (true)
                            : (subEvent.date ? ((new Date() <= new Date(subEvent.date.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '') + " 11:59 PM EST"))
                                ? (true) : (false)) : false))) : false
                return (
                    <Card className={classes.card} key={subEvent.url}>
                        {/* <React.Fragment key={subEvent.url}> */}
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h5" component="h2">
                                {subEvent.name}
                            </Typography>
                            <Typography gutterBottom variant="body1" color="textSecondary" component="h4">
                                {subEvent.time ? ((new Date() <= new Date(subEvent.date.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '') + " 11:59 PM EST")) ? (subEvent.date + ' (' + subEvent.time + ")") : subEvent.date) : subEvent.date}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="div">
                                {subEvent.seriesSummary ? subEvent.seriesSummary : subEvent.summary}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {
                                doesRsvpShow ?
                                    (<Button endIcon={<OpenInNew />} href={`/events/${subEvent.url}/rsvp`} rel="noreferrer" target="_blank" color="primary" variant="outlined">
                                        RSVP
                                    </Button>) : null
                            }
                            <Button component={LinkBehavior} to={`/events/${subEvent.url}`} color="primary" variant={doesRsvpShow ? 'text' : 'outlined'} >
                                More details
                            </Button>
                        </CardActions>
                        {/* <CardHeader
                            title={subEvent.name}
                            subheader={
                                subEvent.time ? ((new Date() <= new Date(subEvent.date.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '') + " 11:59 PM EST")) ? (subEvent.date + ' (' + subEvent.time + ")") : subEvent.date) : subEvent.date
                            }
                        /> */}
                        {/* </React.Fragment> */}
                    </Card>
                )
            })
        }
    }
    var metaDescription = "";
    // try {
    //     metaDescription = event.summary.props.children[0]
    // } catch (err) {
    //     metaDescription = "Bridging the gap between theory and practice through interactive, hands-on events focused on developer tools."
    // }
    metaDescription = "Bridging the gap between theory and practice through interactive, hands-on events focused on developer tools."
    return (
        <div className="event_page">
            <Helmet>
                <title>{`${event.name} | FAU Google Developer Student Club`}</title>
                <meta name="description" value={metaDescription} />
                <link rel="canonical" href={`https://gdscfau.com/events/${params.event}`} />
            </Helmet>
            <section className="event_description">
                <div className="event">
                    <EventBadge event={event} />
                    <h3>{event.name}</h3>
                    {
                        event.type.map((type) => {
                            return (
                                <strong className="event-type" key={type}>{type}</strong>
                            )
                        })
                    }
                    {
                        event.time ? ((new Date() <= eventDateAsDate) ? showDateWithTime() : showDate()) : showDate()
                    }
                    <br />
                    {
                        event.showVideo === true ? (
                            <EventVideo event={event} />
                        ) : null
                    }
                    {
                        event.rsvp ? (
                            event.rsvp.forceShow === false ? null : (
                                event.rsvp.forceShow === true ?
                                    (<EventButtons event rsvp={'/events/' + event.url + '/rsvp'} />)
                                    : (event.date ? (new Date() <= eventDateAsDate ? (<EventButtons event rsvp={'/events/' + event.url + '/rsvp'} />) : (null)) : null))) : null
                    }

                    <br />
                    {
                        event.series && subEventsContent.length > 0 ? (
                            <EventSection>
                                <span className="section_title">Events in this series</span>
                                <br />
                                <Hidden mdUp >
                                    <div style={{ textAlign: 'center' }}>
                                        {
                                            showAllEventsInSeries === false ?
                                                <Button onClick={() => { setShowAllEventsInSeries(true) }} color="primary">Show all events in this series</Button>
                                                :
                                                <Button onClick={() => { setShowAllEventsInSeries(false) }} color="primary">Only show some events in this series</Button>
                                        }
                                        <br /><br />
                                    </div>
                                </Hidden>
                                {
                                    showAllEventsInSeries === false ?
                                        <Box display={{ xs: 'none', sm: 'block', md: 'none' }}>

                                            <Grid container spacing={3}>
                                                {
                                                    subEventsContent.map((gridItem, index) => {
                                                        if (index > 1) return null;
                                                        return (
                                                            <Grid className={classes.gridItem} item xs={12} sm={subEventsContent.length > 1 ? (6) : 12} md={4} key={index}>{gridItem}</Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Box> : null
                                }
                                {
                                    showAllEventsInSeries === false ?
                                        <Box display={{ xs: 'block', sm: 'none' }}>
                                            <Grid container spacing={3}>
                                                {
                                                    subEventsContent.map((gridItem, index) => {
                                                        if (index > 0) return null;
                                                        return (
                                                            <Grid className={classes.gridItem} item xs={12} sm={6} md={4} key={index}>{gridItem}</Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Box> : null
                                }
                                {
                                    showAllEventsInSeries === true ?
                                        <Hidden mdUp>
                                            <Grid container spacing={3}>
                                                {
                                                    subEventsContent.map((gridItem, index) => {
                                                        return (
                                                            <Grid className={classes.gridItem} item xs={12} sm={6} md={4} key={index}>{gridItem}</Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Hidden> : null
                                }
                                {
                                    <Hidden smDown>
                                        <Grid container spacing={3}>
                                            {
                                                subEventsContent.map((gridItem, index) => {
                                                    return (
                                                        <Grid className={classes.gridItem} item xs={12} sm={6} md={4} key={index}>{gridItem}</Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Hidden>
                                }
                                <br />
                            </EventSection>

                        ) : null
                    }
                    {
                        parent ? (
                            <EventSection>
                                <span className="section_title">This event is part of a series</span>
                                <p>
                                    This event is part of the <Link to={parent.url}>{parent.name}</Link> series.
                                </p>

                            </EventSection>
                        ) : null
                    }
                    {
                        event.sections.map((section, index) => {
                            return (
                                section.predefined ? (
                                    getPredefinedSection(section, `section-${index}`)

                                ) : (

                                    <EventSection key={`section-${index}`}>
                                        <span className="section_title">{section.title}</span>
                                        {section.paragraphs ? section.paragraphs.map((paragraph, p_index) => {
                                            return (
                                                <React.Fragment key={`s${index}p${p_index}`}>{paragraph}</React.Fragment>
                                            )
                                        }) : null}
                                    </EventSection>
                                )

                            )
                        })
                    }
                </div>
            </section>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        events: state.events
    }
}
export default connect(mapStateToProps, () => { return {} })(Event)
