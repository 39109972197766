import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../styles/About.css';
import TeamImage from '../../images/team.png';
import AboutPerson from '../PageComponents/AboutPerson';
import { Helmet } from 'react-helmet';

class About extends Component {
    render() {
        const { about } = this.props;
        const { officers, /* advisor */ } = about;
        return (
            <div className="about_page">
                <Helmet>
                    <title>{`About Us | FAU Google Developer Student Club`}</title>
                    <meta name="description" value="Learn about our amazing team of officers who make our events possible." />
                    <link rel="canonical" href={`https://gdscfau.com/about`} />
                </Helmet>
                <section className="discover-main-section">
                    <div className="call-to-action">
                        <h1 className="title">Meet our Team</h1>
                        We have an amazing team of Officers which makes it possible to hold events and workshops.
                        {/* <br /><br />
                        Interested in being an officer?
                        <br /> */}
                        {/* Tech Lead applications will open soon. Elections will take place at the beginning of the Fall semester. */}
                        {/* <br /><br /> */}
                        {/* Faculty Advisor: <a className="faculty-sponser" href={advisor.link}>{advisor.name}</a> */}
                    </div>
                    <img className="team-image" alt="" src={TeamImage} />
                </section>
                <section className="team_description">
                    {
                        officers ? (
                            officers.map((officer) => {
                                return (officer.visible === false) ? (null) : (
                                    <AboutPerson key={officer.name} officer={officer}></AboutPerson>
                                )
                            })
                        ) :
                            (<div>Can't find any officers? Please report this to an officer.</div>)

                    }
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        about: state.about,
    }
}

export default connect(mapStateToProps, () => { return {} })(About);