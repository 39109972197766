import dscDefault from '../images/events/dsc-default.png';
import { Link } from 'react-router-dom';

/**
 * @typedef {Object} BlogPost
 * @property {number} id - ID for the post (ensure it is unique and does not change)
 * @property {string} name - Title of the post
 * @property {string} summary -  First paragraph of your article, as a string
 * @property {Array} body - Body, Array of JSX elements
 * @property {Date} publishDate - Date of the original posting
 * @property {Date} [updatedDate] - Date of last update (will use publish Date if not provided)
 * @property {Object} author
 * @property {string} author.name - Name of the author
 * @property {string} author.initials - Initials of author
 * @property {String} [author.image] - Image (use imports NOT a url)
 */

/**
 * @type {Array<BlogPost>}
 */
const blogPosts = [
    {
        id: 1,
        name: "Welcome to the FAU DSC Blog!",
        // summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultrices neque ornare aenean euismod elementum. Donec massa sapien faucibus et molestie ac feugiat sed. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Mi in nulla posuere sollicitudin. Amet nisl suscipit adipiscing bibendum est ultricies integer.",
        body: [
            <p>Welcome to the FAU Google Developer Student Club Blog! Stay tuned for some upcoming posts!</p>,
            <p>Interested in the events we run? Check out our <Link className="link" to="/events">events page</Link> to learn more about what we do and see our upcoming events.</p>,
        ],
        publishDate: new Date("Feb 15 2021 4:00 PM EST"),
        updatedDate: new Date("Feb 15 2021 4:00 PM EST"),
        mainTag: "welcome",
        tags: ['welcome'],
        author: {
            name: "FAU Google Developer Student Club",
            initials: 'DSC',
            image: dscDefault,
        }
    },
]

export default blogPosts;