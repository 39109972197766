import React from 'react'

function PageCircles() {
    return (
        <div>
            <div className="home-page-circle-1"></div>
            <div className="home-page-circle-2"></div>
            <div className="home-page-circle-3"></div>
            <div className="home-page-circle-4"></div>
        </div>
    )
}

export default PageCircles
