import React from 'react'
import { Redirect } from 'react-router-dom';

function BlogRedirects(props) {
    let newPage = '/blog';
    if (props.match.params.blogPost) {
        newPage += '/' + props.match.params.blogPost;
    }
    if (props.match.params.name) {
        newPage += '/' + props.match.params.name;
    }
    return (
        <Redirect to={newPage} />
    )
}

export default BlogRedirects
