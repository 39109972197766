import React from 'react'
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import '../../styles/upcoming.css';
import { compose } from 'redux';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';

const LinkBehavior = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} {...props} />
));

function UpcomingEventBanner(props) {
    const [collapsed, setCollapsed] = React.useState(false);
    function toDate(d) {
        return (new Date(d.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '') + " 11:59 PM EST"));
    }
    var isUpcomingEvents = false;
    var upcomingEvent;
    const upcomingEvents = props.events.filter(e => {
        if (!e.date) return false;
        if (e.hideFromUpcoming) return false;
        if (toDate(e.date) > (new Date())) {
            return true;
        }
        return false;
    })
    if (upcomingEvents.length > 0) {
        isUpcomingEvents = true;
        upcomingEvent = upcomingEvents[0];
    }
    // isUpcomingEvents = true;
    if (isUpcomingEvents) {
        if (props.location.pathname === `/events/${upcomingEvent.url}`) {
            if (collapsed === false)
                setCollapsed(true)
        } else {
            if (collapsed === true)
                setCollapsed(false)
        }
        return (
            <React.Fragment>
                <Fade timeout={500} in={!collapsed}>
                    <Collapse timeout={500} in={!collapsed}>
                        <div className="upcoming_event_banner">
                            <p>
                                Upcoming Event on {upcomingEvent.date} {/* - <strong>{upcomingEvent.name}</strong><br /> */}
                                <Button component={LinkBehavior} to={"/events/" + upcomingEvent.url} variant="outlined" style={{ textColor: '#fff' }}>
                                    {upcomingEvent.name}
                                </Button>
                            </p>
                        </div>
                        <br /><br /><br />
                    </Collapse>
                </Fade>
            </React.Fragment>
        )
    } else {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.events
    }
}
export default compose(connect(mapStateToProps, () => { return {} }), withRouter)(UpcomingEventBanner)