// import csj1Icon from '../images/events/64x64/csj1.png';
import python from '../images/events/64x64/python.png';
// import django from '../images/events/64x64/dj192.png';
// import cloudshell from '../images/events/64x64/cloudshell.png';
import git from '../images/events/64x64/git.png';
// import compute from '../images/events/64x64/compute.png';
// import csj2Icon from '../images/events/64x64/csj2.png';
// import csj3Icon from '../images/events/64x64/csj3.png';
// import csj4Icon from '../images/events/64x64/csj4.png';
// import flutterIcon from '../images/events/64x64/flutter.png';
// import functions from '../images/events/64x64/functions.png';
// import functions from '../images/events/Cloud Functions.svg';
// import dscDefault from '../images/events/64x64/dsc-default.png';
import pong from '../images/events/64x64/pong.png';
// import regex from '../images/events/64x64/regex.png';
import hackapalooza from '../images/events/64x64/hackapalooza.png';
// import neuralNetworks from '../images/events/64x64/howneuralnetworkslearn.png';
// import android1 from '../images/events/64x64/android-wk1.png';
import tfGde from '../images/events/64x64/tf-ws-gde.png';
import pdd from '../images/events/64x64/pdd.png';
import generators from '../images/events/64x64/generators.png';
import aiHurricanes from '../images/events/64x64/ai-hurricanes.jpg';

var navbar = [
    { name: "Home", to: '/' },
    { name: "About Us", to: '/about' },
    // {
    //     name: "2019-2020 Events",
    //     to: '/events/#2019-20-events',
    //     hash: true,
    // },
    {
        name: "Events",
        to: '/events',
        activeOnSubPath: true,
        dropdownItems: [
            {
                name: "Upcoming Events in Fall",
                section: true,
            },
            {
                name: "AI in Hurricane Prediction",
                to: '/events/ai-hurricanes',
                image: aiHurricanes,
            },
            {
                name: "Recent Events",
                section: true,
            },
            {
                name: "TensorFlow Workshop with a GDE",
                to: '/events/tensorflow-workshop',
                image: tfGde,
            },
            {
                name: "Intro to Python Generators",
                to: '/events/generators',
                image: generators,
            },
            {
                name: "Professional Development Day",
                to: '/events/pdd',
                image: pdd,
            },
            // {
            //     name: "Ongoing Events",
            //     section: true,
            // },
            // {
            //     name: "Hackapalooza (Hackathon)",
            //     section: true,
            // },

            {
                name: "Workshops",
                section: true,
            },
            // {
            //     name: "How Neural Networks Learn",
            //     to: '/events/neuralnetworks',
            //     image: neuralNetworks,
            // },
            {
                name: "Hackapalooza (Hackathon)",
                to: '/events/hackapalooza',
                image: hackapalooza,
            },
            // {
            //     name: "Intro to Competitive Programming",
            //     to: "/events/competitiveprogramming",
            //     image: competitive,
            // },
            // {
            //     name: "Rock with Regex",
            //     to: '/events/regex',
            //     image: regex,
            // },
            {
                name: "Build Pong with Python",
                to: '/events/pong',
                image: pong,
            },

            // {
            //     name: "Intro to Cloud Functions",
            //     to: '/events/functions',
            //     image: functions,
            // },

            // {
            //     name: "Intro to Django",
            //     to: "/events/django1",
            //     image: django,
            // },
            {
                name: "Intro to Python Series",
                to: "/events/python",
                image: python,
            },
            {
                name: "All About Git",
                to: '/events/all-about-git',
                image: git,
            },

            // {
            //     name: "Previous Events",
            //     section: true,
            // },
            // {
            //     name: "2019-2020 Events",
            //     to: '/events/#2019-20-events',
            //     image: '',
            //     hash: true,
            // },
        ]
    },
    // { name: "Blog", to: '/blog' }
]

export default navbar;