import React from 'react';
import DscLogo from '../../images/logo.png';
import { Link } from 'react-router-dom';
import '../../styles/Home.css';
import Button from '../PageComponents/Button';
import PageContent from '../PageComponents/PageContent';
import ButtonGroup from '../PageComponents/ButtonGroup';
import { Helmet } from 'react-helmet';

function Home() {
    return (
        <PageContent>
            <Helmet>
                <title>{`Home | FAU Google Developer Student Club`}</title>
                <meta name="description" value="Bridging the gap between theory and practice." />
                <link rel="canonical" href={`https://gdscfau.com/`} />
            </Helmet>
            <div className="title-section">
                <span className="title-logo">
                    <a href="/">
                        <img alt="google developers bracket" src={DscLogo} />
                    </a>
                    <Link to="/" className="title-logo-name-a">
                        <div className="title-logo-name">
                            <div className="">Google Developer Student Clubs</div>
                            <div className="title-logo-school">Florida Atlantic University</div>
                        </div>
                    </Link>
                </span>
            </div>
            <span className="subtitle" style={{ textAlign: 'center' }}>Helping students bridge the gap between <span className="highlight"><strong>theory</strong></span> and <span className="highlight"><strong>practice</strong></span>.</span>
            <span className="content">
                The Google Developer Student Club at Florida Atlantic University is committed
                to catering to students interested Google Developer technologies. Students have the opportunity to
                expand their knowledge and build new skills in a peer-to-peer learning environment with the
                resources and support they need to succeed.
                <br /><br />
                Our goal is to have a positive impact on our local community, so we encourage students to use Google
                Developer technologies to solve problems they see around them. Google Developer Student Clubs is a
                community where everyone from all majors is welcome.
            </span>
            <span className="content">To get updates about our club, select a button below.</span>
            <ButtonGroup>
                <Button href="/interest" useLink spaceAfter>Interest Form</Button>
                <Button href="https://discord.gg/39qJ3sE" secondary spaceAfter>Discord</Button>
                <Button href="https://fau.campuslabs.com/engage/organization/gdsc" secondary spaceAfter>Owl Central</Button>
            </ButtonGroup>

        </PageContent>
    )
}

export default Home;
