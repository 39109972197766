import React from 'react'

function ButtonGroup(props) {
    return (
        <div className="btn-action">
            {props.children}
        </div>
    )
}

export default ButtonGroup
