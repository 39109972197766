import React from 'react'

function EventVideo(props) {
    const { event } = props;
    return (
        <React.Fragment>
            <iframe className='youtube_embed' width="720" height="405" title={`${event.name}${event.type ? '' + event.type[0] : ''} event video`} srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}</style>
                <style>span{height:1.5em;text-align:center;font:60px/1.5 sans-serif;color:white;text-shadow:0 0 0.3em black}</style>
                <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons'>
                <style>.m-icon{ font-family: 'Material Icons';
                font-weight: normal;
                font-style: normal;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                -webkit-font-feature-settings: 'liga';
                pointer-events: none;
                -webkit-font-smoothing: antialiased;</style>
                <a href='https://www.youtube.com/embed/${event.videoId}?autoplay=1'><img src='${event.videoImageUrl}' alt='${event.name}${event.type ? '' + event.type[0] : ''} event video'><span class='m-icon icon'>play_circle_filled</span></a>"`}
                frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            <p className="yt-embed-under">
                <a href={`https://youtu.be/${event.videoId}`} target="_blank" rel="noreferrer">
                    Having trouble viewing the video? Click here to open in a new tab.
                </a>
            </p>
        </React.Fragment>
    )
}

export default EventVideo
