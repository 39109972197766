import React from 'react'

function AboutPerson(props) {
    const { officer } = props;

    if (officer.group) {
        return <div key={"group_" + officer.group} className="officer_section_sep">
            {officer.group}
        </div>;
    }
    const { name, position, bio, image } = officer;
    var { badgeLink } = officer;
    if (badgeLink === '') badgeLink = '#';
    // import OfficerImage from `../../images/officers/${imageName}`
    const imageLinkProps = badgeLink === '#' ? {} : {
        target: "_blank",
        rel: "noreferrer"
    }

    if (badgeLink === '#') badgeLink = '#' + name.replace(/ /g, "_");

    return (
        <div id={name.replace(/ /g, "_")} className="person">
            <div className="person-badge">
                <a href={badgeLink} {...imageLinkProps}>
                    <img className="normal" src={image} alt={`gdsc ${position}: ${name}`} />
                </a>
            </div>
            <h3>{name}</h3>
            <strong>{position}</strong>
            <p>
                {bio}
            </p>
        </div>
    )
}

export default AboutPerson;