import React, { Component } from 'react';
import EventSection from './EventSection';

class PreReqBuildAMobileApp extends Component {
    getDefaultState = () => {
        var platform = navigator.platform;
        // windows: Win32
        // mac: MacIntel, MacPPC, Mac68K
        var isMac = platform.toLowerCase().indexOf('mac') >= 0;
        if (isMac) {
            return 'mac'
        } else {
            return 'win'
        }
    }
    state = {
        show: this.getDefaultState(),
    }
    toggleOs = () => {
        if (this.state.show === 'win') {
            this.setState({
                show: 'mac'
            })
        } else {
            this.setState({
                show: 'win'
            })
        }
    }
    render() {
        return (
            <EventSection>
                <div>
                    <span>No prior Flutter or development experience required.</span>
                </div>
                <div><br />
                    <span>For this
                        event,
                        you'll need a computer running Windows, macOS, Linux, or another operating system supported by
                        Flutter.</span><br /><br /></div>

                {this.state.show === 'mac' ?
                    <div id="macOS">
                        For macOS users:
                        <ul className="prereqs">
                            <li><span className="prereq">Flutter</span><br />You'll need to have Flutter installed for this
                                workshop. You can install <a target="_blank" rel="noreferrer"
                                    href="https://flutter.dev/docs/get-started/install/macos">Flutter
                                    for macOS here</a>.
                            </li>
                            <li><span className="prereq">Android Studio (optional)</span><br />On macOS, you can use Xcode to
                                test
                                your apps on an iOS Simulator or device. If you'd like to test your apps on Android, you'll
                                need
                                to install Android Studio. You can install <a target="_blank" rel="noreferrer"
                                    href="https://developer.android.com/studio">Android
                                    Studio here</a>.
                            </li>
                            <li><span className="prereq">Xcode</span><br />To run your apps on iOS Simulators or devices, you'll
                                need to
                                have <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/xcode/id497799835?mt=12">the
                                    latest version of
                                    Xcode</a> installed.</li>
                            <li><span className="prereq">A code editor</span><br />During this workshop, Jonathan will be using
                                <a target="_blank" rel="noreferrer" href="https://code.visualstudio.com/Download">Visual Studio Code</a>.
                                However, you can use
                                whichever editor you'd like, but we recommend VS Code or Android Studio. Using Xcode as your
                                editor is not recommended for Flutter development.</li>
                        </ul>
                    </div> :
                    <div id="windows">
                        For Windows and Linux users:
                        <ul className="prereqs">
                            <li><span className="prereq">Flutter</span><br />You'll need to have Flutter installed for this
                                workshop. You can install <a target="_blank" rel="noreferrer"
                                    href="https://flutter.dev/docs/get-started/install/windows">Flutter for
                                    Windows here</a> or <a target="_blank" rel="noreferrer"
                                        href="https://flutter.dev/docs/get-started/install/linux">Flutter for
                                    Linux here</a>.
                            </li>
                            <li><span className="prereq">Android Studio</span><br />In order to test your app on Android
                                devices,
                                you'll need to install Android
                                Studio. You can install <a target="_blank" rel="noreferrer"
                                    href="https://developer.android.com/studio">Android
                                    Studio here</a>.
                            </li>
                            <li><span className="prereq">A code editor</span><br />During this workshop, Jonathan will be using <a rel="noreferrer" target="_blank" href="https://code.visualstudio.com/Download">Visual Studio Code</a>.
                                However, you can use
                                whichever editor you'd like, but we recommend VS Code or Android Studio.</li>
                        </ul>
                    </div>}
                <button onClick={this.toggleOs} id="switchBtn" className="btn btn-secondary">See Prerequisites for
                    {" "}{this.state.show === 'mac' ? 'Windows and Linux' : 'macOS'}</button>
                <br /><br />
            </EventSection>
        );
    }
}

export default PreReqBuildAMobileApp;