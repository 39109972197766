import React from 'react'
import PageContent from '../PageComponents/PageContent';
import Button from '../PageComponents/Button'
import ButtonGroup from '../PageComponents/ButtonGroup';
import { Helmet } from 'react-helmet';

function NotFound(props) {
    const { event, blog } = props;
    return (
        <PageContent>
            <Helmet>
                <title>{`Page Not Found | FAU Google Developer Student Club`}</title>
                <meta name="description" value="Bridging the gap between theory and practice." />
                <link rel="canonical" href={`https://gdscfau.com${props.location.pathname}`} />
            </Helmet>
            {
                event ? (
                    <Helmet>
                        <title>{`Event Not Found | FAU Google Developer Student Club`}</title>
                        <meta name="description" value="Bridging the gap between theory and practice through interactive, hands-on events focused on developer tools." />
                    </Helmet>
                ) : null
            }
            {
                blog ? (
                    <Helmet>
                        <title>{`Blog Post Not Found | FAU Google Developer Student Club`}</title>
                        <meta name="description" value="Bridging the gap between theory and practice through interactive, hands-on events focused on developer tools." />
                    </Helmet>
                ) : null
            }
            <h1 id="not-found-title" className="title">{event ? "Event" : (blog ? "Blog Post" : "Page")} Not Found</h1>
            <span id="not-found-message" className="subtitle">The {event ? "event" : (blog ? "blog post" : "page")} you were looking for could not be found.</span>
            <span className="content">
                Something missing? We've recently recreated our website and some things might be missing. <br /><br />
                <a href={`https://docs.google.com/forms/d/e/1FAIpQLScSQM5WadlSOIC-cUvurAuQB-VG8fcSogZ3loj0kGn0YakakA/viewform?usp=pp_url&entry.1753636239=${encodeURIComponent(window.location.href)}`} target="_blank" className="link" rel="noreferrer">Please report missing content here.</a>
            </span>
            <ButtonGroup>
                {event ? <Button href="/events" useLink spaceAfter>See other events</Button> : null}
                {blog ? <Button href="/blog" useLink spaceAfter>See other blog posts</Button> : null}
                <Button id="not-found-home-btn" useLink secondary={event || blog} href="/">Home Page</Button>
            </ButtonGroup>
        </PageContent>
    )
}

export default NotFound
