import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import DscLogo from '../../images/logo.png'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons'
import NavDropdown from './NavDropdown';
import MobileNavbar from './MobileNavbar';


class Navbar extends Component {
    state = {
        windowWidth: window.innerWidth,
        mobileNavOpen: false,
    }

    handleResize = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    toggleMobileNav = (open = null) => {
        this.setState({
            mobileNavOpen: ((open === true || open === false) ? open : !this.state.mobileNavOpen)
        })
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    render() {
        const { windowWidth } = this.state;

        var navItems = this.props.navbar.length === 0 ? (null) : (
            this.props.navbar.map((navItem, index) => {
                if (navItem.dropdownItems === undefined) {
                    return (
                        <li key={index} className="itm">
                            <NavLink exact={navItem.activeOnSubPath === true ? false : true} to={navItem.to}>{navItem.name}</NavLink>
                        </li>
                    )
                } else {
                    return (
                        <li key={index} className="itm">
                            <NavDropdown navItem={navItem} />
                            {/* <NavLink exact={navItem.activeOnSubPath === true ? false : true} to={navItem.to}>{navItem.name}</NavLink> */}
                        </li>
                    )
                }
            })
        )




        return (
            <div>
                <header className="main-header">
                    <span className="brand-logo">
                        <Link to="/">
                            <img alt="gdsc google developers bracket" src={DscLogo} />
                        </Link>
                        <Link to="/" className="brand-logo-name-a">
                            <div className="brand-logo-name">
                                <div>Google Developer Student Clubs</div>
                                <div className="brand-logo-school">Florida Atlantic University</div>
                            </div>
                        </Link>
                    </span>
                    <nav className="main-nav">
                        <div className="navbar">
                            <ul id="nav">
                                {/* mobile trigger */}
                                {/* navigation items */}
                                {
                                    (windowWidth < 806) ?
                                        (<li className="mobile-trigger" key="mobile_trigger">
                                            <IconButton onClick={this.toggleMobileNav}>
                                                <Menu />
                                            </IconButton>

                                        </li>) : null
                                }
                                {(windowWidth >= 806) ? (navItems) : null}
                            </ul>
                        </div>
                    </nav>
                </header>
                {
                    (windowWidth >= 806) ? (null) : <nav className="r-dmobile-nav">
                        <MobileNavbar navbar={this.props.navbar} open={this.state.mobileNavOpen} toggleMobileNav={this.toggleMobileNav} />
                    </nav>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        navbar: state.navbar,
    }
}

export default compose(connect(mapStateToProps, () => { return {} }), withRouter)(Navbar);
