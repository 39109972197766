import React, { Component } from 'react';
import '../../../styles/HelpPage.css'
import GitNotFound from '../../../images/flutter-install/git-not-found.png'
import Img2 from '../../../images/flutter-install/img2.png'
import Img3 from '../../../images/flutter-install/img3.png'
import Img4 from '../../../images/flutter-install/img4.png'
import Img5 from '../../../images/flutter-install/img5.png'
import Img6 from '../../../images/flutter-install/img6.png'
import Img7 from '../../../images/flutter-install/img7.png'
import Img8 from '../../../images/flutter-install/img8.png'
import Img9 from '../../../images/flutter-install/img9.png'
import { Tabs, Tab } from '@material-ui/core';
import { Helmet } from 'react-helmet';

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class FlutterInstall extends Component {
    initialState = () => {
        if (window.location.search) {
            var search = new URLSearchParams(window.location.search);
            if (search.get('os') === 'mac' || search.get('os') === 'macos') {
                return 1
            }
            if (search.get('mac') !== null || search.get('macos') !== null) {
                return 1
            }
        }
        return 0
    }
    state = {
        os: this.initialState(),
    }

    switchOs = (event, toOs) => {
        this.setState({
            os: toOs,
        })
    }
    // switchToMac = () => {
    //     this.switchOs(1)
    // }
    // switchToMac = () => {
    //     this.switchOs(0)
    // }

    render() {
        return (
            <div className="help_page">
                <Helmet>
                    <title>{`Flutter Installation Guide | FAU Google Developer Student Club`}</title>
                    <meta name="description" value="Learn how to install Flutter so you can participate in our hands-on Flutter workshops." />
                    <link rel="canonical" href={`https://gdscfau.com${this.props.location.pathname}`} />
                </Helmet>
                <section className="event-main-section">
                    <div className="call-to-action">
                        <h1 className="title" style={{ marginBottom: 0 }}>Flutter Install Guide</h1>
                        <span className="subtitle">Provided by Jonathan Yataco</span><br /><br />
                        If you're participating in our Flutter workshops, you will need to have
                        Flutter installed prior to the start of the event.
                        <br /><br />
                        We've put together some instructions to help get you started, but note that this information serves as a
                        supplement to the installation instructions found on the official Flutter website.
                        <br /><br />
                        For the full official instructions, navigate to <a target="_blank" rel="noreferrer"
                            href="https://flutter.dev/docs/get-started/install">flutter.dev/docs/get-started</a>
                    </div>

                </section>
                <section id="introduction">
                    <h4 className="title" style={{ "fontSize": "2em" }}>Introduction</h4>
                    <div>
                        <b>NOTE: </b>This document serves as a supplement to the installation instructions that are found
                        on the flutter website. Flutter is
                        available to install on all major operating systems and the official instructions for installing it on each
                        OS can be
                        found here:
                        <ul>
                            <li><a target="_blank" rel="noreferrer" href="https://flutter.dev/docs/get-started/install/windows">Windows Official Installation
                                Guide</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://flutter.dev/docs/get-started/install/macos">MacOS Official Installation Guide</a>
                            </li>
                            <li><a target="_blank" rel="noreferrer" href="https://flutter.dev/docs/get-started/install/linux">Linux Official Installation Guide</a>
                            </li>
                        </ul>
                        <br />
                        This document will only go over instructions for installing Flutter on Windows and Mac OS. Both documents will only cover setting up Flutter for use with emulators. For instructions on how to deploy a Flutter app to your actual iOS/Android device, please refer to the <b>official</b> installation guides. Again this document is a supplementary reference to the official guides.
                        <br /><br />
                        <ul>
                            <li><a target="_blank" rel="noreferrer" className="windows_btn" href="#windows">Windows supplementary reference</a></li>
                            <li><a target="_blank" rel="noreferrer" className="mac_btn" href="#macos">MacOS supplementary reference</a></li>
                        </ul>
                        <br />
                        After following the official instructions or the supplementary references, you can set up a text editor that will be used for flutter development. Instructions on how to set up a text editor/IDE can be found <a target="_blank" rel="noreferrer" href="https://flutter.dev/docs/get-started/editor?tab=androidstudio">here</a>. Both Android Studio and Visual Studio Code can be used for Flutter development and both have great Flutter/Dart plugins to support your development environment.

                        <br /><span id="questions"></span><br />
                        If you have any questions during installation, post your question on the <b>#tech-help</b> channel of our official Discord server <a target="_blank" rel="noreferrer" href="https://go.dscfau.com/discord">https://go.dscfau.com/discord</a>. Thanks 👨🏽‍💻
                    </div>
                </section>
                <Tabs
                    value={this.state.os}
                    onChange={this.switchOs}
                    centered>
                    <Tab label="Windows"{...a11yProps(0)} />
                    <Tab label="macOS" {...a11yProps(1)} />
                </Tabs>

                <br />
                {this.state.os !== 1 ? (
                    <section id="windows">
                        <h3 style={{ "fontSize": "2em" }} className="title">Windows Guide</h3>
                        <section id="windows-requirements">
                            <span className="subtitle">Part 1 - Requirements</span>
                            <ul>
                                <li>Operating Systems: Windows 7 SP1 or later (64-bit)</li>
                                <li>Disk Space: 400 MB (does not include disk space for IDE/tools).</li>
                                <li><a target="_blank" rel="noreferrer" href="https://docs.microsoft.com/en-us/powershell/scripting/install/installing-windows-powershell">Windows PowerShell 5.0</a> or newer (this is pre-installed with Windows 10)</li>
                                <li><a target="_blank" rel="noreferrer" href="https://git-scm.com/download/win">Git for Windows</a> 2.x, with the Use Git from the Windows Command Prompt option.</li>
                            </ul>
                            <br />
                            Make sure you meet these requirements before continuing. Follow the embedded links to install Windows PowerShell 5.X and Git for Windows 2.X. Windows PowerShell 5.x is already pre-installed with Windows 10, but it is especially important to download Git since that does not come pre-installed with windows. For example, if I try to run the git command on a fresh install of Windows 10 I get the following error.
                            <div className="step-img" data-name="git-not-found.png">
                                <a target="_blank" rel="noreferrer" href={GitNotFound}>
                                    <img alt="windows 'git' output before install" src={GitNotFound} />
                                </a>
                            </div>
                            <br />
                            Git offers a 32-bit version and a 64-bit version on the download page. It is important to download the version that corresponds to your operating system. To find out if the version of windows that you are running is 32 or 64 bit, do the following:
                            <div className="step-img" data-name="img2.png">
                                <a target="_blank" rel="noreferrer" href={Img2}>
                                    <img alt="instructions to determine 32 or 64 bit" src={Img2} />
                                </a>
                            </div>
                            <br />
                            You will see this information and the <b>System Type</b> will either tell you if you are running 32- or 64- bit.
                            <div className="step-img" data-name="img3.png">
                                <a target="_blank" rel="noreferrer" href={Img3}>
                                    <img alt="sample system type output" src={Img3} />
                                </a>
                            </div>
                            <br />
                            Once you have this information, download the appropriate version of git for Windows. The download will be an .exe, and when opened you will be prompted to set up git. The setup will ask you to configure git on your system but the default settings are fine for our purposes. Just keep clicking <b>next</b> during the setup. The following is an example of what the setup looks like:
                            <div className="step-img" data-name="img4.png">
                                <a target="_blank" rel="noreferrer" href={Img4}>
                                    <img alt="windows git installation screen" src={Img4} />
                                </a>
                            </div>
                            <br />

                        </section>
                        <section id="windows-flutter-install">
                            <span className="subtitle">Part 2 - Flutter Install/Setup</span>
                            <ol className="help-list">
                                <li>Download the following installation bundle to get the latest stable release of the Flutter SDK: <a target="_blank" rel="noreferrer"
                                    href="https://storage.googleapis.com/flutter_infra/releases/stable/windows/flutter_windows_1.17.4-stable.zip">flutter_windows_1.17.4-stable.zip</a>
                                </li>
                                <li>Extract the zip file and place the contained <pth is="custom">flutter</pth> in the desired installation location for the Flutter SDK (for example, <pth is="custom">C:\src\flutter</pth>; do not install Flutter in a directory like <pth is="custom">C:\Program Files\</pth> that requires elevated privileges).
                                    <ol>
                                        <li>For example, I’m going to create a new folder in my C:\ drive called src. I will extract/place the Flutter SDK in here</li>
                                    </ol>
                                </li>
                                <li>
                                    Once extracted, we want to update our system path so that we can use flutter commands from anywhere in the windows console, rather than just from the directory where flutter is installed.
                                    <ol>
                                        <li>From the Start search bar, enter ‘env’ and select Edit environment variables for your account.</li>
                                        <li>Under User variables check if there is an entry called Path:
                                            <ol>
                                                <li>If the entry exists, append the full path to <pth is="custom">flutter\bin</pth> using <pth is="custom">;</pth> as a separator from existing values.</li>
                                                <li>If the entry doesn’t exist, create a new user variable named <pth is="custom">Path</pth> with the full path to <pth is="custom">flutter\bin</pth> as its value.</li>
                                                <li>In order to find out the full path name of <b>flutter\bin</b>, navigate to the folder <b>flutter</b> folder inside src. Locate the bin directory and right click on the bin directory while holding down the <b>shift</b> key.
                                                    <ol>
                                                        <li>You should be given the option to “copy as path”. Clicking this option will copy the full system path to <b>flutter\bin</b> to your system clipboard.</li>
                                                        <li>You should see the “copy as path” option as shown on the right. Once clicked, follow the instructions above to add to path. Note that if the path is copied with quotation marks around it, remove them before adding to the system path. To confirm if this was successful, run flutter doctor and you should receive output in windows prompt.
                                                            <div className="step-img" data-name="img5.png">
                                                                <a target="_blank" rel="noreferrer" href={Img5}>
                                                                    <img alt="right-click menu on windows after git installation" src={Img5} />
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <br />
                            Screenshot example of new path after editing:
                            <div className="step-img" data-name="img6.png"><a target="_blank" rel="noreferrer" href={Img6}>
                                <img alt="windows edit PATH environment variable screen" src={Img6} />
                            </a></div>
                            <div className="step-img" data-name="img7.png">
                                <a target="_blank" rel="noreferrer" href={Img7}>
                                    <img alt="windows edit environment variables screen" src={Img7} />
                                </a>
                            </div>
                            <br />Example of output from running the flutter doctor command:
                            <div className="step-img" data-name="img8.png">
                                <a target="_blank" rel="noreferrer" href={Img8}>
                                    <img alt="sample output for 'flutter doctor'" src={Img8} />
                                </a>
                            </div>
                        </section>
                        <br />
                        <section id="windows-android-studio">
                            <span className="subtitle">Part 3 - Android Studio and Emulator Setup</span>
                            <br /><br />
                            <em>Note that you can use and develop flutter apps with other IDE’s or text editors such as visual studio code. The official installation instructions as well as this document will only cover setup using Android studio, since Android studio is needed to run an Android emulator whether or not you use the IDE for editing code.</em>
                            <br /><br />
                            <span style={{ "fontSize": "1.2em" }} className="subtitle">Setting up Android Studio</span>
                            <br />
                            <ol>
                                <li>Download and install <a target="_blank" rel="noreferrer" href="https://developer.android.com/studio">Android Studio</a>.</li>
                                <li>Start Android Studio, and go through the 'Android Studio Setup Wizard'. This installs the latest Android SDK, Android SDK Command-line Tools, and Android SDK Build-Tools, which are required by Flutter when developing for Android. Choose the standard option during the Android Studio Setup Wizard. This will install everything that is needed.</li>
                            </ol>
                            <span style={{ "fontSize": "1.2em" }} className="subtitle">Setting up Android Emulator</span>
                            <br />
                            <ol>
                                <li>Enable <a target="_blank" rel="noreferrer" href="https://developer.android.com/studio/run/emulator-acceleration">VM acceleration</a> on your machine. The section that explains how to enable VM acceleration will be farther down the page, and it will be titled <em>“Configuring VM acceleration”</em>. Note this has to be done on an actual machine, and cannot be enabled through a virtual machine running windows.</li>
                                <li>{"Launch Android Studio > Tools > Android > AVD Manager and select Create Virtual Device. (The Android submenu is only present when inside an Android project.)"}</li>
                                <li>Choose a device definition and select Next.</li>
                                <li>Select one or more system images for the Android versions you want to emulate, and select Next.
                                    An <em>x86</em> or <em>x86_64</em> image is recommended.</li>
                                <li>Under Emulated Performance, select Hardware - GLES 2.0 to enable <a target="_blank" rel="noreferrer" href="https://developer.android.com/studio/run/emulator-acceleration"> hardware acceleration</a>.</li>
                                <li>Verify the AVD configuration is correct, and select Finish. For details on the above steps, see <a target="_blank" rel="noreferrer" href="https://developer.android.com/studio/run/managing-avds">Managing AVDs</a>.
                                </li>
                                <li>In Android Virtual Device Manager, click Run in the toolbar. The emulator starts up and displays the default canvas for your selected OS version and device.</li>
                            </ol>
                            <br />
                            Once this is configured, you have your windows environment setup to develop flutter apps 😃. <a href="#questions">Questions?</a>
                        </section>
                    </section>)
                    : (
                        <section id="macos">
                            <Helmet>
                                <title>{`Flutter Installation Guide (Mac OS) | FAU Google Developer Student Club`}</title>
                                <link rel="canonical" href={`https://gdscfau.com${this.props.location.pathname}`} />
                            </Helmet>
                            <h3 style={{ "fontSize": "2em" }} className="title">Mac Guide</h3>
                            <section id="mac-requirements">
                                <span className="subtitle">Part 1 - Requirements</span>
                                <br />
                                To install and run Flutter, your development environment must meet these minimum requirements:
                                <ul>
                                    <li>Operating Systems: macOS (64-bit)</li>
                                    <li>Disk Space: 2.8 GB (does not include disk space for IDE/tools).</li>
                                    <li>Tools: Flutter depends on these command-line tools being available in your environment.
                                        <ul>
                                            <li>bash</li>
                                            <li>curl</li>
                                            <li>git 2.x</li>
                                            <li>mkdir</li>
                                            <li>rm</li>
                                            <li>unzip</li>
                                            <li>which</li>
                                            <li>zip</li>
                                        </ul>
                                    </li>
                                    <li>Note that if you are running a relatively recent version of MacOS, then all of the listed tools will
                                        automatically be installed on your machine. This document assumes that you know how to navigate the
                                        system using terminal, but if you have any trouble or don’t have any experience doing so then please
                                        ask questions in the Discord 🙂.</li>
                                </ul>
                            </section>
                            <section id="mac-flutter-install">
                                <span className="subtitle">Part 2 - Flutter Install</span>
                                <ol className="help-list">
                                    <li>Download the following installation bundle to get the latest stable release of the Flutter SDK: <a target="_blank" rel="noreferrer" href="https://storage.googleapis.com/flutter_infra/releases/stable/macos/flutter_macos_1.17.4-stable.zip">flutter_macos_1.17.4-stable.zip</a>.You can also clone the flutter repo to your desired location on your machine by running the following command:
                                        <ol>
                                            <li>
                                                <code>git clone https://github.com/flutter/flutter.git</code>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Extract the file in the desired location. For example, you can create a development folder and unzip the zip file in there:
                                        <br />
                                        <div className="codeblock bash">
                                            <code>
                                                <span className="cd">cd</span> ~/development<br />
                                                unzip ~/Downloads/flutter_macos_1.17.4-stable.zip
                                            </code>
                                        </div>
                                    </li>
                                    <li>
                                        Add <code>flutter</code> to your path. You are going to want to add it to your system path so that you can run flutter commands from everywhere, so perform the following steps:
                                        <ol>
                                            <li>Determine the directory where you placed the Flutter SDK. You need this in Step 3. You can get the full path to this by cd’ing to the directory where Flutter is installed, and running the <strong><b><em>pwd</em></b></strong> command. The output will look like this:
                                                <div className="step-img" data-name="img9.png">
                                                    <a target="_blank" rel="noreferrer" href={Img9}>
                                                        <img alt="'pwd' command output" src={Img9} />
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                Open (or create) the <code><pth is="custom">rc</pth></code> file for your shell. Typing
                                                <code><pth is="custom">echo $SHELL</pth></code> in your Terminal tells you which shell you’re using. If you’re using Bash, edit <code><pth is="custom">$HOME/.bash_profile</pth></code> or <code><pth is="custom">$HOME/.bashrc</pth></code>. If you’re using Z shell, edit <code><pth is="custom">$HOME/.zshrc</pth></code>. If you’re using a different shell, the file path and filename will be different on your machine.
                                            </li>
                                            <li>
                                                Add the following line and change <code><pth is="custom">[PATH_TO_FLUTTER_GIT_DIRECTORY]</pth></code> to be the path where you cloned Flutter’s git repo or the path where you unzipped flutter:
                                                <ol>
                                                    <li>
                                                        <div className="codeblock bash">
                                                            <code>
                                                                export PATH="$PATH:[PATH_TO_FLUTTER_GIT_DIRECTORY]/flutter/bin"
                                                            </code>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>Run <pth is="custom"><code>source $HOME/.&lt;rc file&gt;</code></pth> to refresh the current window, or open a new terminal window to automatically source the file.</li>
                                            <li>Verify that the <code><pth is="custom">flutter/bin</pth></code> directory is now in your PATH by running:
                                                <ol>
                                                    <li>
                                                        <div className="codeblock bash"><code>echo $PATH</code></div>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Verify that that the flutter command is available by running: <code>which flutter</code>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </section>
                            <section id="mac-xcode-setup">
                                <span className="subtitle">Part 3 - Xcode and Simulator Setup</span>
                                <br />
                                <em>Note that MacOS gives you the ability to run Android simulators as well as iOS simulators. This guide only covers setting up iOS simulators; refer to the official installation instructions for setting up Android emulators for MacOS. Setting up Xcode is necessary for running iOS simulators and for running Flutter apps on a physical iOS device.</em>
                                <br /><br />
                                <span className="subtitle" style={{ "fontSize": "1.1em" }}>Setting Up Xcode</span>
                                <ol className="help-list">
                                    <li>Install the latest stable version of Xcode (using <a target="_blank" rel="noreferrer" href="https://developer.apple.com/xcode/">web download</a> or the <a target="_blank" rel="noreferrer" href="https://itunes.apple.com/us/app/xcode/id497799835">Mac App Store</a>).</li>
                                    <li>Configure the Xcode command-line tools to use the newly-installed version of Xcode by running the following from the command line:
                                        <ol>
                                            <div className="codeblock">
                                                <code>
                                                    sudo xcode-select --switch /Applications/Xcode.app/Contents/Developer<br />
                                                    sudo xcodebuild -runFirstLaunch
                                                </code>
                                            </div>
                                        </ol>
                                    </li>
                                    <li>Make sure the Xcode license agreement is signed by either opening Xcode once and confirming or running sudo <code>xcodebuild -license</code> from the command line.</li>
                                </ol>
                                <br /><br />
                                <span className="subtitle" style={{ "fontSize": "1.1em" }}>Setting up an iOS Simulators</span>
                                <ol className="help-list">
                                    <li>On your Mac, find the Simulator via Spotlight or by using the following command:<br />
                                        <div className="codeblock"><code>open -a simulator</code></div>
                                    </li>
                                    <li>{"Make sure your simulator is using a 64-bit device (iPhone 5s or later) by checking the settings in the simulator’s Hardware > Device menu."}</li>
                                    <li>{"Depending on your development machine’s screen size, simulated high-screen-density iOS devices might overflow your screen. Set the device scale under the Window > Scale menu in the simulator."}</li>

                                </ol>
                                <span className="subtitle" style={{ "fontSize": "1.1em" }}>Run a simple flutter app to test if setup worked:</span>
                                <ol className="help-list">
                                    <li>Create a new Flutter app by running the following from the command line:<br />
                                        <div className="codeblock"><code>flutter create my_app</code></div>
                                    </li>
                                    <li>A my_app directory is created, containing Flutter’s starter app. Enter this directory:<br />
                                        <div className="codeblock"><code>cd my_app</code></div>
                                    </li>
                                    <li>To launch the app in the Simulator, ensure that the Simulator is running and enter:<br />
                                        <div className="codeblock"><code>flutter run</code></div>
                                    </li>

                                </ol>
                                If this worked, then you should see the simulator launch an app called my_app. It will have a counter on the screen that you can push and watch the counter grow. This may take time to load depending on the machine. If this all worked, you have your Mac environment setup to develop flutter apps 😃. <a target="_blank" rel="noreferrer" href="#questions">Questions?</a>
                            </section>

                        </section>
                    )}
            </div>
        );
    }
}

export default FlutterInstall;