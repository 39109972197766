import React from 'react'
import EventSection from './EventSection';

function StudyJamPredefined() {
    return (
        <EventSection>
            <span className="section_title">Study Jams</span>
            <p>Study Jams are group learning programs designed to give students practical experience in Google Cloud
            technologies. Students come together to build their skills through interactive lessons and hands-on
                labs.</p>
            <p>Upon completing a Study Jam quest, attendees earn a cool badge to show off their skills, and of course,
            it can be added to their LinkedIn profiles. Students also gain full access to Qwiklabs for up to two
                months, allowing them to explore the GCP and other Cloud technologies further until the next meetup!</p>
        </EventSection>
    )
}

export default StudyJamPredefined
