import React from 'react';
import { ListItemText, ListItemIcon, MenuItem, Typography, MenuList } from '@material-ui/core';
import { Popper, Paper } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import { withRouter, useLocation } from 'react-router-dom'

import { usePopupState, bindPopper } from 'material-ui-popup-state/hooks'
import { bindHover } from 'material-ui-popup-state'
import dscDefault from '../../images/events/dsc-default.png';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
        margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
}));



function MenuItemLink({ item, popupState }) {
    // const { name, to, location, handleMenuClick, hash } = props;
    const location = useLocation();

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <NavLink to={item.to} ref={ref} {...itemProps} />),
        [item.to],
    );
    // const renderHashLink = React.useMemo(
    //     () => React.forwardRef((itemProps, ref) => <NavHashLink to={to} ref={ref} {...itemProps} />),
    //     [to],
    // );

    const showAsSelected = (location.pathname === item.to) ? true : false;

    return (
        // // <MenuItem selected={showAsSelected} onClick={handleMenuClick} component={hash ? renderHashLink : renderLink} to={to}>
        // <MenuItem selected={showAsSelected} onClick={handleMenuClick} component={renderLink} to={to}>

        //     {name}</MenuItem>

        <MenuItem component={renderLink} to={item.to} selected={showAsSelected} onClick={popupState.close}>
            {/* <ListItemAvatar>
                            <Avatar>
                                <img src={item.image} />
                            </Avatar>
                        </ListItemAvatar> */}
            <ListItemIcon>
                <Icon>
                    <img alt="" height="24px" width="24px" src={item.image ? item.image : dscDefault} class="nav_dropdown_icon" />
                </Icon>
            </ListItemIcon>
            <ListItemText primary={item.name} />
        </MenuItem>

    );
}

function NavDropdown(props) {
    const classes = useStyles();
    const { navItem } = props;

    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'nav_dropdown_' + navItem.name.split(' ').join('-')
    })

    const dropdownItems = (navItem.dropdownItems.length === 0) ? <div /> : (
        navItem.dropdownItems.map((item, index) => {
            if (item.section !== true) {
                return (
                    <MenuItemLink key={index} item={item} popupState={popupState} />
                )
            } else {
                return <div key={index}>
                    {/* <Divider component="li" /> */}
                    <li>
                        <Typography
                            className={classes.dividerFullWidth}
                            color="textSecondary"
                            display="block"
                            variant="caption"
                        >
                            {item.name}
                        </Typography>
                    </li>
                </div>
            }
        })
    )


    function ActualDropdown() {
        return (
            // <Menu
            //     {...bindMenu(popupState)}
            //     getContentAnchorEl={null}
            //     anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            //     transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            // >
            //     <MenuItem onClick={popupState.close}>Cake</MenuItem>
            //     <MenuItem onClick={popupState.close}>Death</MenuItem>
            // </Menu>
            <Popper
                // onMouseOver={handlePopperMouseOver}
                // onMouseOut={handlePopperMouseOut}
                {...bindPopper(popupState)}

            // getContentAnchorEl={null}
            // anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            // transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            // transition
            >
                {/* {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}> */}
                <Paper>
                    {/* <MenuItem onClick={popupState.close}>
                        <ListItemIcon>
                            <Cake />
                        </ListItemIcon>
                        <ListItemText primary="Cake" />
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="Death" />
                    </MenuItem> */}
                    <MenuList>
                        {dropdownItems}
                    </MenuList>
                </Paper>
                {/* </Fade>
                )} */}
            </Popper>
        )
    }

    return (
        <React.Fragment>
            <div {...bindHover(popupState)}
                // onMouseOver={popupState.open}
                // onMouseOut={handlePopupClose}
                className="dd_div">
                <NavLink
                    // ref={anchorRef(popupState)} 
                    exact={navItem.activeOnSubPath === true ? false : true}
                    to={navItem.to}>{navItem.name}
                </NavLink>
                {/* <div style={{ marginTop: 10 }}> */}
                <ActualDropdown />
                {/* </div> */}

            </div>
            {/* <div onMouseOver={popupState.open} style={{ padding: 10 }} /> */}
        </React.Fragment>
    );
}

export default withRouter(NavDropdown);